import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { AccountRecord } from "tap-types/lib/AccountService/account_record";
import { syncLists } from "../list-slice/list-slice";
import { syncWorkflows } from "../workflow-slice/workflow-slice";

export class SyncHelper{

    static dispatchSyncUpdate = (records: AccountRecord[], dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        dispatch(syncWorkflows(records));
        dispatch(syncLists(records));
    }
}