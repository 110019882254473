export enum EventType {
    FetchWorkflows = 'FETCH_WORKFLOWS',
    CreateWorkflow = 'CREATE_WORKFLOW',
    UpdateWorkflow = 'UPDATE_WORKFLOW',
    DeleteWorkflow = 'DELETE_WORKFLOW',
    UpdateWorkflowStatuses = 'UPDATE_WORKFLOW_STATUSES',
    CreateWorkflowRecord = 'CREATE_WORKFLOW_RECORD',
    CreateVisitor = 'CREATE_VISITOR',
    UpdateVisitor = 'UPDATE_VISITOR',
    DeleteVisitor = 'DELETE_VISITOR',
    CreateInvite = 'CREATE_INVITE',
    UpdateInvite = 'UPDATE_INVITE',
    UpdateWorkflowRecord = 'UPDATE_WORKFLOW_RECORD',
    DeleteWorkflowRecord = 'DELETE_WORKFLOW_RECORD',
    AddSiteSubscription =  'ADD_SITE_SUBSCRIBTION_TO_WORKFLOW',
    RemoveSiteSubscription =  'REMOVE_SITE_SUBSCRIBTION_TO_WORKFLOW',
    AddRoleSubscription =  'ADD_ROLE_SUBSCRIBTION_TO_WORKFLOW',
    RemoveRoleSubscription =  'REMOVE_ROLE_SUBSCRIBTION_TO_WORKFLOW',
    CreateUser = 'CREATE_USER',
    UpdateUser = 'UPDATE_USER',
    DeleteUser = 'DELETE_USER',
    CreateRole = 'CREATE_ROLE',
    UpdateRole = 'UPDATE_ROLE',
    DeleteRole = 'DELETE_ROLE',
    CreateList = 'CREATE_LIST',
    UpdateList = 'UPDATE_LIST',
    DeleteList = 'DELETE_LIST',
    UpdateSiteList = 'UPDATE_SITE_LIST',
    createOrUpdateLocation = 'CREATE_OR_UPDATE_LOCATION',
    createCompanionAppUser = 'CREATE_COMPANION_APP_USER',
    updateCompanionAppUser = 'UPDATE_COMPANION_APP_USER',
    //...we can add as we go along
}

