/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export namespace APIQueries {
  export const getUser = /* GraphQL */ `
    query GetUser {
      getUser {
        companyAccountId
        email
        firstName
        isCompanyAccount
        lastName
        phone
        username
        defaultAccount{
          username
          accountId
        }
        accounts {
          accountId
          email
          locationIds
          permissions
          roleIds
          companyName
        }
      }
    }
  `;

  export const syncAccountRecords = /* GraphQL */ `
    query SyncAccountRecords($accounts: [SyncAccountRecordsInput!]!) {
      syncAccountRecords(accounts: $accounts) {
        Attributes
        Date
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        IsActive
        OTP
      }
    }
  `;

  export const getWorkflowRecords = /* GraphQL */ `
    query GetWorkflowRecords ($request: GetWorkflowRecordsInput!){
      getWorkflowRecords(request: $request) {
        Attributes
        Date
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        IsActive
        OTP
      }
    }
  `;

  export const fetchPaymentPlans = /* GraphQL */ `
    query FetchPaymentPlans {
      fetchPaymentPlans{
        SK
        Type
        PlanName
        Cost
        Plan
        Benefits
        _lastChangedAt
        _version
        _deleted
        _createdBy
      }
    }
`;

export const getPaymentResults = /* GraphQL */ `
    query GetPaymentResults ($payload: GetPaymentResultsInput!){
      getWorkflowRecords(payload: $payload) {
        Date
        Amount
        PaymentDetails
        Email
        IsPaymentSuccessful
        PaymentStatus
        PaddedCardNumber
        CurrencyCode
        CurrencyText
        AuthorizationNumber
      }
    }
  `;

}



export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        PK
        SK
        Type
        Attributes
        Date
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;