import React from 'react';
import AuthFlowWrapper from '../components/Auth/AuthFlowWrapper/AuthFlowWrapper';
class SignIn extends React.Component<{}, {}>{

    render(){
        return (
            <div>
               <AuthFlowWrapper/>
            </div>
        )
    }
}

export default SignIn;
