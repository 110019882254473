import {get, set} from 'idb-keyval';

export class PersistedQueue {
    private constructor(
        private readonly KEY: string,
        private readonly events: any[]
    ){

    }

    public enqueue = async (item: any): Promise<boolean> => {
        try {    
            this.events.push(item);
            await set(this.KEY, this.events);
            return true;
        } catch (error) {
            this.events.pop();
            return false;
        }
    
    }

    public dequeue = async () => {

        let dequeuedEvent: any;
        try {
            const noEventAtHead = this.events.length === 0;
    
            if (noEventAtHead) {
                return undefined;
            }
            
            dequeuedEvent = this.events.shift()!;
            await set(this.KEY, this.events);
            return dequeuedEvent;
        } catch (error) {
            if (!dequeuedEvent!) {
                throw error;
            }
    
            this.events.unshift(dequeuedEvent);
            throw error;
        }
    
    }

    peek = () => {
        const noEventAtHead = this.events.length === 0;
    
        if (noEventAtHead) {
            return undefined;
        }
    
        return this.events[0];
    
    }
    
    length = () => {    
        return this.events.length;
    }
    
    clear = async () => {
        await set(this.KEY, undefined);
        this.events.length = 0;
    }


    public static createAsync = async (key: "RESPONSEQUEUE" | "EVENTQUEUE") => {
        const events = await PersistedQueue.initializeEvents(key);
        return new PersistedQueue(key, events);
    }

    private static initializeEvents = async (key: string) => {
        const savedEvents = await get(key) as any[];
    
        return savedEvents ?? [];
    }

}

