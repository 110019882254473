import { ResponseEvent } from "../../../../models/response-event";
import responseQueue from "./response-event-queue";

import { factory } from "./response-dispatch-factory";

const TIMEOUT = 1000;

interface ResponseQueueState {
  state: "busy" | "idle";
}

let queueInterval: NodeJS.Timeout;

let responseQueueState: ResponseQueueState = { state: "idle" };

const setQueueState = (state: "busy" | "idle") =>
  (responseQueueState.state = state);

const dispatchStateUpdate = async () => {
  setQueueState('busy');
  let response = await responseQueue.peek();

  if (!response) {
    setQueueState('idle');
    return;
  }

  resolveDispatchAction(response);
  await responseQueue.dequeue();

  setQueueState('idle');

  const queueLength = await responseQueue.length();

  if (queueLength !== 0) {
    dispatchStateUpdate();
  }
};

const resolveDispatchAction = (responseEvent: ResponseEvent) => {
  try {
    const dispatchHandler = factory(responseEvent.event.eventType);

    if (!dispatchHandler) return;

    const handler = new dispatchHandler();

    if (handler.canHandleOperation()) {
    }
    handler.handleOperation(responseEvent);
  } catch (error) {
    //TODO: handle properly
    responseQueue.clear();
    console.error(`Error updating state ${error}`);
  }
};

const processQueuedResponses = () => {
  console.log('Processing responses');
  queueInterval = setInterval(queueIntervalHandler, TIMEOUT);
}

const queueIntervalHandler = () => {
  if (responseQueueState.state === 'idle') {
    dispatchStateUpdate();
  }
}

const stopProcessingQueueResponses = () => {
  if (queueInterval) {
    clearInterval(queueInterval)
  }
}

const responseEventQueueManager = {
  processQueuedResponses,
  stopProcessingQueueResponses
}

export default responseEventQueueManager;
