import React, { useRef } from 'react'
import { Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import './FloatingLogo.css'

const FloatingLogo: React.FunctionComponent<{}> = () => {
    const link = useRef<HTMLAnchorElement>(null);
    const goHome = () => link.current?.click();
    
    return (
        <>
            <div id="floating__logo__wrapper" onClick={goHome}>
                <div id="floating__logo">
                    <Image src={logo} alt="Tap Sign In" />
                    <span>Tap Sign In</span>
                </div>
            </div>
            <Link to='/' ref={link} hidden/>
        </>
    );
}

export default FloatingLogo
