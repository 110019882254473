import { EventType } from "../../../../models/event-type";
import {
    callbackOnAddSiteSubscription,
    callbackOnSiteUnsubscription,
    callbackOnRoleSubscription,
    callbackOnRoleUnsubscription,
    callbackOnAccountRecord,
  } from "../../../../redux/slices/workflow-slice/workflow-slice";
import AppStore from "../../../../redux/store";
import { ResponseEvent } from "../../../../models/response-event";

const {store} = AppStore();
const workflowRecordEventTypes = [EventType.CreateInvite, EventType.CreateVisitor, EventType.createOrUpdateLocation];
abstract class BaseSyncCallback {
    eventType: EventType;
    constructor(type: EventType){
        this.eventType = type;
    }
    public abstract canHandleOperation(): boolean;
    public abstract handleOperation(response: ResponseEvent): void;
}

class AddRoleSubscriptionCallback extends BaseSyncCallback {

    public canHandleOperation(): boolean {
        return this.eventType === EventType.AddRoleSubscription;
    }
    public handleOperation(response: ResponseEvent){
        store.dispatch(callbackOnRoleSubscription(response));
    }
}

class RemoveRoleSubscriptionCallback extends BaseSyncCallback {

    public canHandleOperation(): boolean {
        return this.eventType === EventType.RemoveRoleSubscription;
    }
    public handleOperation(response: ResponseEvent):void{
        store.dispatch(callbackOnRoleUnsubscription(response));
    }
}

class AddSiteSubscriptionCallback extends BaseSyncCallback {

    public canHandleOperation(): boolean {
        return this.eventType === EventType.AddSiteSubscription;
    }
    public handleOperation(response: ResponseEvent): void{
        
        store.dispatch(callbackOnAddSiteSubscription(response));
    }
}

class RemoveSiteSubscriptionCallback extends BaseSyncCallback {
    public canHandleOperation(): boolean {
        return this.eventType === EventType.RemoveSiteSubscription;
    }
    public handleOperation(response: ResponseEvent):void{
        
        store.dispatch(callbackOnSiteUnsubscription(response));
    }
}

class AccountRecordCallback extends BaseSyncCallback {

    public canHandleOperation(): boolean {
        return !workflowRecordEventTypes.some(event => event === this.eventType);
    }
    public handleOperation(response: ResponseEvent): void {
        
        store.dispatch(callbackOnAccountRecord(response));
    }
}

export function factory(event: EventType): { new <T extends BaseSyncCallback>(): T } {
    const handlers = new Map<EventType, any>([ 
        [EventType.AddSiteSubscription, AddSiteSubscriptionCallback.bind(AddSiteSubscriptionCallback, event)], 
        [EventType.RemoveSiteSubscription, RemoveSiteSubscriptionCallback.bind(RemoveSiteSubscriptionCallback, event)],
        [EventType.AddRoleSubscription, AddRoleSubscriptionCallback.bind(AddRoleSubscriptionCallback, event)],
        [EventType.RemoveRoleSubscription, RemoveRoleSubscriptionCallback.bind(RemoveRoleSubscriptionCallback, event)]
    ]);

    const handler = handlers.get(event) ;

    if(!handler){
        return AccountRecordCallback.bind(AccountRecordCallback, event) as  { new <T extends BaseSyncCallback>(): T };
    }
     
    return handler as { new <T extends BaseSyncCallback>(): T };
};