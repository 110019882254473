import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GetUserQuery } from '../../../API';
import { APIQueries } from '../../../graphql/queries';
import { get } from 'idb-keyval';
import { checkOnlineStatus } from "../../../utilities/useOnlineStatus/checkOnlineStatus";
import { Tap } from 'tap-types';
import { CognitoUser, currentAuthenticatedUser } from "../../../services/auth";


export const LOGGED_IN_USERS = 'LOGGED_IN_USERS';

export async function fetchSomething() {
    // *Working with this
    // try {
    //     const PaymentPlanModel = await API.graphql({
    //         query: 'FETCH_PAYMENT_PLAN_QUERY',
    //     }) as PaymentPlanModel[];
    //     return PaymentPlanModel;
    // } catch (error) {
    //     console.log(error);
    // }
    //! For testing purposes
    return new Promise<{ data: any[] }>((resolve) =>
        setTimeout(() => resolve({
            data: [

            ]
        }), 500)
    );
}

export async function fetchLoggedInUser() {
    try {
        //*offline check
        const online = await checkOnlineStatus();
        if(!online) {
            const users =  await get<Tap.UserService.User[]>(LOGGED_IN_USERS) || [];
            const username = (await currentAuthenticatedUser() as CognitoUser)?.username;
            const user = users.find(u => u.username === username);
            if(user) return user;
            throw new Error('User not found');
        }
        const result = await (API.graphql({
            query: APIQueries.getUser
        }) as Promise<GraphQLResult<GetUserQuery>>);
        
        return result.data?.getUser;// as unknown as Tap.UserService.User;
    } catch (error) {
        throw error;
    }
}