import { GraphQLResult } from "@aws-amplify/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Tap } from "tap-types";
import { API } from "aws-amplify";
import { SyncAccountQuery } from "../../../API";
import { APIQueries } from "../../../graphql/queries";
import { RootState } from "../../store";
import { SyncHelper } from "./sync-helper";

export interface SyncState {
    lastSynced: number,
    error: string | undefined;
    status: 'idle' | 'loading' | 'failed' | 'success';
}

const initialSyncState: SyncState = {
    lastSynced: 0,
    error: undefined,
    status: 'idle'
};

export const syncRecords = createAsyncThunk(
    'sync/syncRecords',
    async (accounts: Tap.AccountService.SyncRequest[], {dispatch}) => {

        if(accounts.length === 0){
            return undefined;
        }

        const result = await (API.graphql({
            query: APIQueries.syncAccountRecords,
            variables: { accounts}
        }) as Promise<GraphQLResult<SyncAccountQuery>>);

        if(!result.data || !result.data.syncAccountRecords){
            throw Error('Failed to sync account records');
        }
        
        const syncedRecords = result.data.syncAccountRecords;

        if(syncedRecords.length === 0){
            return undefined;
        }
        
        SyncHelper.dispatchSyncUpdate(syncedRecords, dispatch);

        let lastSynced = 0;

        syncedRecords.forEach(record => {
            if( record._lastChangedAt > lastSynced){
                lastSynced = record._lastChangedAt;
            }
        });
        
        return lastSynced;
    }
);

export const syncSlice = createSlice({
    name: "sync",
    initialState: initialSyncState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(syncRecords.fulfilled, (state, action) => {
            state.lastSynced = action.payload ?? state.lastSynced;
            state.status = 'success';
            state.error = undefined;
        }).addCase(syncRecords.rejected, (state, action) => {
            state.error = action.error.message;
            state.status = 'failed';
        })
    },
});

export const selectSyncStatus = (state: RootState) => state.sync.status;
export default syncSlice.reducer;