import { createAsyncThunk, Draft, createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';
import { Workflow } from '../../../models/workflow';
import { AddWorkflowRecord } from '../../../interfaces/worklow-slice/add-workflow-record';
import { DeleteWorkflowRecord } from '../../../interfaces/worklow-slice/delete-workflow-record';
import { RoleSubscription } from '../../../interfaces/worklow-slice/role-subscription';
import { SiteSubscription } from '../../../interfaces/worklow-slice/site-subscription';
import TapRow from '../../../models/tap-row';
import { RootState } from '../../store';
import requestEventQueueManager from '../../../services/sync/event/request/request-event-queue-manager';
import { RequestEvent } from '../../../models/request-event';
import { EventType } from '../../../models/event-type';
import { UpdateWorkflowRecord } from '../../../interfaces/worklow-slice/update-workflow-record';
import { WorkflowFieldItem } from '../../../interfaces/workflow-field-item';
import { Tap } from 'tap-types';
import Status from '../../../models/status';
import { AccountRecord } from 'tap-types/lib/AccountService/account_record';
import { RecordType } from 'tap-types/lib/AccountService/record_type';
import { CompanionAppUserProperties, TapLocationProperties, TapRoleProperties, TapSiteProperties, TapUserProperties, UserFields } from '../../../constants';
import { WorkflowHelpers } from '../../../helpers/workflow-helpers';
import { API } from 'aws-amplify';
import { APIQueries } from '../../../graphql/queries';
import { GetWorkflowRecordsQuery } from '../../../API';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { ResponseEvent } from '../../../models/response-event';
import { AccountService, TapTables } from 'tap-types/lib/AccountService';
import { WorkItemState } from '../../../models/work-item-state';
import { APIMutations } from '../../../graphql/mutations';

export interface WorkflowState {
  workflows: Workflow[];
  status: 'idle' | 'loading' | 'success' | 'failed';
  site: string | undefined
  error: string | undefined
  lastSynced: string
}

const initialState: WorkflowState = {
  workflows: [],
  status: 'idle',
  site: undefined,
  error: undefined,
  lastSynced: '',
};

export const addSiteSubscriptionRequest = createAsyncThunk(
  'workflow/addSiteSubcription',
  async (siteSubscription: SiteSubscription, { getState }) => {
    const { workflowId, siteId, accountId } = siteSubscription;
    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(w => w.id === workflowId && w.accountId === accountId);

    if (!workflow) throw Error('Targeted workflow does not exist');

    const siteSubscriptionRequest = new RequestEvent(
      { siteId: siteId, workflowId: workflowId },
      EventType.AddSiteSubscription
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(siteSubscriptionRequest);

    if (isHandled) {
      return siteSubscription;
    } else {
      throw Error('Failed to enqueue add site subscription request');
    }

  }
);

export const acceptInvitation = createAsyncThunk(
  "workflow/acceptInvite",
  async (invitation: Tap.DynamicLinkParams) => {
    const response = await (API.graphql({
      query: APIMutations.acceptAccountInvite,
      variables: { ...invitation },
    }) as Promise<GraphQLResult<any>>);

    if (response.errors) {
      throw new Error('Error accepting invite');
    }

    return invitation;
  }
);

export const removeSiteSubscriptionRequest = createAsyncThunk(
  "workflow/removeSiteSubscription",
  async (siteSubscription: SiteSubscription, { getState }) => {
    const { siteId, workflowId, accountId } = siteSubscription;
    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(w => w.id === workflowId && w.accountId === accountId);

    if (!workflow) {
      throw new Error('Targeted workflow not found');
    }

    const index = workflow.siteSubscriptions.indexOf(siteId);

    if (index < 0) {
      throw new Error('Site not found in subscription list.');
    }

    const siteUnsubscriptionRequest = new RequestEvent(
      { siteId: siteId, workflowId: workflowId },
      EventType.RemoveSiteSubscription,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(siteUnsubscriptionRequest);

    if (isHandled) {
      return siteSubscription;
    } else {
      throw Error('Failed to enqueue unsubscribe site request');
    }
  }
);

export const addRoleSubscriptionRequest = createAsyncThunk(
  'workflow/addRoleSubcription',
  async (roleSubscription: RoleSubscription, { getState }) => {
    const { workflowId, roleId, accountId } = roleSubscription;
    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(w => w.id === workflowId && w.accountId === accountId);

    if (!workflow) throw Error('Targeted workflow does not exist');

    const roleSubscriptionRequest = new RequestEvent(
      { roleId: roleId, workflowId: workflowId },
      EventType.AddRoleSubscription
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(roleSubscriptionRequest);

    if (isHandled) {
      return roleSubscription;
    } else {
      throw Error('Failed to enqueue add role subscription request');
    }

  }
);

export const removeRoleSubscriptionRequest = createAsyncThunk(
  "workflow/removeRoleSubscription",
  async (roleSubscription: RoleSubscription, { getState }) => {
    const { roleId, workflowId, accountId } = roleSubscription;
    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);

    if (!workflow) {
      throw new Error('Targeted workflow not found');
    }

    const index = workflow.roleSubscriptions.indexOf(roleId);

    if (index < 0) {
      throw new Error('Role not found in subscription list.');
    }

    const siteUnsubscriptionRequest = new RequestEvent(
      { roleId: roleId, workflowId: workflowId },
      EventType.RemoveRoleSubscription,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(siteUnsubscriptionRequest);

    if (isHandled) {
      return roleSubscription;
    } else {
      throw Error('Failed to enqueue unsubscribe role request');
    }
  }
);

export interface CreateWorkflowRequestInput {
  fields: WorkflowFieldItem[]
  name: string,
  description: string
  siteIds?: string[]
  accountId: string
}

export const createWorkflowRequest = createAsyncThunk(
  'workflow/create',
  async (args: CreateWorkflowRequestInput, { getState }) => {

    const state = getState() as RootState;

    if (!state.auth.user) {
      throw new Error('User undefined');
    }

    const userEmail = state.auth.user.accounts.find(account => account.accountId === args.accountId)?.email;


    if (!userEmail) {
      throw new Error('Could not get user for request');
    }

    const workflow: Workflow = {
      id: nanoid(),
      accountId: args.accountId,
      fields: args.fields,
      name: args.name,
      roleSubscriptions: [],
      description: args.description,
      rows: [],
      searchText: '',
      typeFilter: '',
      siteSubscriptions: args.siteIds ?? [],
      statuses: [],
      userSubscriptions: [],
      sync: 'new',
      createdBy: userEmail,
      deleted: false,
      version: 1,
    }

    const requestInput = new Tap.AccountService.Workflow.AccountWorkflowInput(args.accountId, workflow.id, userEmail, {
      fields: workflow.fields,
      id: workflow.id,
      name: workflow.name,
      description: args.description,
      roleSubscriptions: workflow.roleSubscriptions,
      siteSubscriptions: workflow.siteSubscriptions,
      userSubscriptions: workflow.userSubscriptions
    });

    const createRequest = new RequestEvent(
      { workflow: requestInput },
      EventType.CreateWorkflow
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(createRequest);

    if (isHandled) {
      return workflow;
    } else {
      throw Error('Failed to enqueue create workflow request');
    }
  }
);

export const updateWorkflowRequest = createAsyncThunk(
  'workflow/update',
  async (updatedWorkflow: Workflow, { getState }) => {
    const state = getState() as RootState;
    const workflowToUpdate = state.workflow.workflows.find(wf => wf.id === updatedWorkflow.id && wf.accountId === updatedWorkflow.accountId);

    if (!workflowToUpdate) {
      throw new Error('Workflow to update not found');
    }

    const updateRequest = new RequestEvent(
      { workflowId: updatedWorkflow.id, workflow: updatedWorkflow }, //possible transformation of arg to fit mutation input
      EventType.UpdateWorkflow,
    );

    updatedWorkflow.sync = 'pending_update';

    const isHandled = await requestEventQueueManager.handleEventRequest(updateRequest);

    if (isHandled) {
      return updatedWorkflow;
    } else {
      throw new Error('Failed to update workflow');
    }
  }
);

export const createOrUpdateLocation = createAsyncThunk(
  'workflow/upsertLocation',
  async (input: { username: string, payload:  Tap.AccountService.List.LocationUpdateArguments }, {getState}) => {
    const { username, payload } = input;

    let location = new Tap.AccountService.List.AccountLocationUpdate(payload);
    
    location = {
      ...location,
      Attributes: JSON.stringify(location.Attributes)
    };

    const upsertLocation = new RequestEvent(
      { userEmail: username, payload: location, accountId: payload.accountId },
      EventType.createOrUpdateLocation,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(upsertLocation);

    if (isHandled) {
      return payload;
    } else {
      throw new Error('Failed to enqueue delete workflow request');
    }
  }
);

export const deleteWorkflowRequest = createAsyncThunk(
  'workflow/delete',
  async (deleteWorkflow: { workflowId: string, accountId: string }, { getState }) => {
    const state = getState() as RootState;
    const { workflowId, accountId } = deleteWorkflow;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);

    if (!workflow) {
      throw new Error('Workflow to delete not found');
    }

    const deleteRequest = new RequestEvent(
      { workflowId: workflowId, accountId }, //possible transformation of arg to fit mutation input
      EventType.DeleteWorkflow,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(deleteRequest);

    if (isHandled) {
      return deleteWorkflow;
    } else {
      throw new Error('Failed to enqueue delete workflow request');
    }
  }
);

//TODO might need to change and do specific types



export const createWorkflowRecordRequest = createAsyncThunk(
  'workflow/create-record',
  async (addRecord: AddWorkflowRecord, { getState }) => {
    const state = getState() as RootState;
    const { workflowId, record } = addRecord;

    if (!state.auth.user) {
      throw new Error('User undefined');
    }

    const user = state.auth.user;
    const userAccount = state.auth.user.accounts.find(account => account.accountId === record.accountId);
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === record.accountId);



    if (!workflow) {
      throw new Error('Workflow not found');
    }

    if (!userAccount) {
      throw new Error('Could not get user for request');
    }

    if (addRecord.type !== EventType.CreateUser) {
      record.id = nanoid();
    }

    addRecord.record.sync = "new";
    const isRecordComplete = isWorkflowRecordComplete(workflow, addRecord.record);

    if (!isRecordComplete) {
      return addRecord;
    }

    let payload: any = WorkflowHelpers.getCreateRecordInput(addRecord.record, addRecord.workflowId, user, state.workflow.workflows)!;

    payload = {
      ...payload,
      Attributes: JSON.stringify(payload.Attributes)
    }

    const request = new RequestEvent({
      accountId: workflow.accountId,
      payload: payload,
      userEmail: userAccount,
    }, WorkflowHelpers.getCreateType(workflow.id))

    addRecord.record.lastSynced = payload._lastChangedAt;
    addRecord.record.createdBy = userAccount.email;
    addRecord.record.version = payload._version;
    addRecord.record.sync = 'pending_create';


    const isHandled = await requestEventQueueManager.handleEventRequest(request);

    if (isHandled) {
      return addRecord;
    } else {
      throw new Error('Failed to enqueue create workflow record request');
    }
  }
);

export const getWorkflowRecords = createAsyncThunk(
  'workflow/get-records',
  async (request: Tap.AccountService.WorkflowRecord.GetWorkflowRecordsRequest, { getState }) => {
    const { accountId, date, workflowId, startDate, endDate } = request;

    const state = getState() as RootState;
    const userEmail = state.auth.user?.accounts.find(account => account.accountId === accountId)?.email;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);

    if (!userEmail) {
      throw new Error('Could not get user for request');
    }

    if (!workflow) {
      throw new Error('Workflow not found');
    }

    const result = await (API.graphql({
      query: APIQueries.getWorkflowRecords,
      variables: {
        request
      }
    }) as Promise<GraphQLResult<GetWorkflowRecordsQuery>>);

    if (!result.data || !result.data.getWorkflowRecords) {
      throw Error('Failed to get workflow records');
    }

    const records = result.data.getWorkflowRecords;
    const transformedRows = transformWorkflowRecords(records, accountId);

    return { transformedRows, accountId, workflowId, date, startDate, endDate };
  }
);

export const updateWorkflowRecordRequest = createAsyncThunk(
  'workflow/update-record',
  async (updateRecord: UpdateWorkflowRecord, { getState }) => {
    const { workflowId, record } = updateRecord;

    const state = getState() as RootState;

    if (!state.auth.user) {
      throw new Error('User undefined');
    }

    const userAccount = state.auth.user.accounts.find(account => account.accountId === record.accountId);
    const user = state.auth.user;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === updateRecord.record.accountId);



    if (!userAccount) {
      throw new Error('Could not get user for request');
    }

    if (!workflow) {
      throw new Error('Workflow not found');
    }

    const recordToUpdate = workflow.rows.find(wr => wr.id === record.id);

    if (!recordToUpdate) {
      throw new Error('Workflow record to update not found');
    }

    const isRecordComplete = isWorkflowRecordComplete(workflow, updateRecord.record);

    if (!isRecordComplete) {
      return updateRecord;
    }

    let request: RequestEvent;

    if (recordToUpdate.sync === 'new' || recordToUpdate.sync === 'pending_create') {

      let payload: any = WorkflowHelpers.getCreateRecordInput(record, workflowId, user, state.workflow.workflows)!;

      payload = {
        ...payload,
        Attributes: JSON.stringify(payload.Attributes)
      }

      request = new RequestEvent({
        accountId: workflow.accountId,
        payload: payload,
        userEmail: userAccount.email,
      }, WorkflowHelpers.getCreateType(workflow.id))

      updateRecord.record.lastSynced = payload._lastChangedAt;
      updateRecord.record.version = payload._version;
      updateRecord.record.sync = 'pending_create';
      updateRecord.record.createdBy = userAccount.email;
    } else {

      let payload: any = WorkflowHelpers.getRecordUpdate(record, workflowId, user, state.workflow.workflows)!;

      payload = {
        ...payload,
        Attributes: JSON.stringify(payload.Attributes)
      }

      request = new RequestEvent({
        accountId: workflow.accountId,
        payload: payload,
        userEmail: userAccount.email
      },
        WorkflowHelpers.getUpdateType(workflow.id),
      );

      updateRecord.record.lastSynced = Date.now();
      updateRecord.record.sync = 'pending_update';
    }

    const isHandled = await requestEventQueueManager.handleEventRequest(request);

    if (isHandled) {
      return updateRecord;
    } else {
      throw new Error('Failed to enqueue create workflow record request');
    }
  }
);

export interface UpdateWorkflowStatuses {
  workflowId: string;
  accountId: string;
  statuses: Status[];
}

export const updateWorkflowStatusesRequest = createAsyncThunk(
  'workflow/status/update',
  async (input: UpdateWorkflowStatuses, { getState }) => {
    const { workflowId, statuses, accountId } = input;

    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);


    if (!workflow) {
      throw new Error('Workflow not found');
    }



    const updateRequest = new RequestEvent(
      { workflowId: workflowId, accountId: workflow.accountId, statuses: statuses }, //possible transformation of arg to fit mutation input
      EventType.UpdateWorkflowStatuses,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(updateRequest);

    if (isHandled) {
      return input;
    } else {
      throw new Error('Failed to enqueue request');
    }
  }
);

export const deleteWorkflowRecordRequest = createAsyncThunk(
  'workflow/record/delete',
  async (deleteRecord: DeleteWorkflowRecord, { getState }) => {
    const { workflowId, recordId, accountId } = deleteRecord;

    const state = getState() as RootState;
    const workflow = state.workflow.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);



    if (!workflow) {
      throw new Error('Workflow not found');
    }



    const recordToDelete = workflow.rows.find(wr => wr.id === recordId);

    if (!recordToDelete) {
      throw new Error('Workflow record to delete not found');
    }

    const deleteRequest = new RequestEvent(
      { workflowId: workflowId, recordId: recordId }, //possible transformation of arg to fit mutation input
      EventType.DeleteWorkflowRecord,
    );

    const isHandled = await requestEventQueueManager.handleEventRequest(deleteRequest);

    if (isHandled) {
      return deleteRecord;
    } else {
      throw new Error('Failed to enqueue delete workflow record request');
    }
  }
);

export const workflowSlice = createSlice({
  name: "workflow",
  initialState: initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSite: (state: Draft<WorkflowState>, action: PayloadAction<string>) => {
      state.site = action.payload;
    },
    syncWorkflows: (state: Draft<WorkflowState>, action: PayloadAction<AccountRecord[]>) => {
      updateWorkflows(action.payload, state);
      updateLocations(action.payload, state);
      updateRoles(action.payload, state);
      updateUsers(action.payload, state);
      updateCompanionAppUsers(action.payload,state);
      updateVisitors(action.payload, state);



      state.workflows = state.workflows.filter(workflow => workflow.deleted !== true);
      state.workflows.forEach(workflow => {
        workflow.rows = workflow.rows.filter(row => row.deleted !== true);
        workflow.statuses = workflow.statuses.filter(status => status.deleted !== true);
      });

      //TODO: add other workflow related types
    },

    //#region  Subscription
    callbackOnAddSiteSubscription: (
      state,
      action: PayloadAction<ResponseEvent>
    ) => {

    },
    callbackOnSiteUnsubscription: (
      state,
      action: PayloadAction<ResponseEvent>
    ) => {
    },

    callbackOnRoleSubscription: (
      state,
      action: PayloadAction<ResponseEvent>
    ) => {
    },
    callbackOnRoleUnsubscription: (
      state,
      action: PayloadAction<ResponseEvent>
    ) => {
    },

    callbackOnAccountRecord: (state, action: PayloadAction<ResponseEvent>) => {
      try {
        const { error, event, responseData } = action.payload;
        let record = {};
        const request = event.variables as Tap.AccountService.AccountRequest;



        if (error) {
          record = {
            ...request.payload,
            Attributes: JSON.parse(request.payload.Attributes)
          }
        } else {
          const response = Object.entries(responseData.data)[0][1] as any;
          record = {
            ...response,
            Attributes: JSON.parse(response.Attributes)
          };
        }






        const accountRecord = new AccountRecord(record);
        let workflowId = '';

        switch (accountRecord.Type) {
          case RecordType.USER:
            workflowId = TapTables.app_users;
            break;
          case RecordType.WORKFLOW:
            workflowId = (accountRecord.Attributes as AccountService.Workflow.WorkflowAttributes).id;
            break;
          case RecordType.INVITE:
          case RecordType.VISITOR:
            workflowId = accountRecord.PK.split('#WORKFLOW#')[1];
            break;
          case RecordType.LOCATION:
            workflowId = TapTables.sites;
            break;
        }

        const relatedWorkflow = state.workflows.find(wf => wf.accountId === request.accountId && wf.id === workflowId);

        if (!relatedWorkflow) {
          return;
        }

        if (accountRecord.Type === RecordType.WORKFLOW) {
          updateCallbackRecord(error, accountRecord, relatedWorkflow);
          return;
        }

        let relatedRecord: any;

        switch (accountRecord.Type) {
          case RecordType.INVITE:
          case RecordType.VISITOR:
            const recordId = (accountRecord.Attributes as Tap.AccountService.WorkflowRecord.RecordAttributes).id;
            relatedRecord = relatedWorkflow.rows.find(row => row.id === recordId);
            break;
          case RecordType.USER:
            const email = (accountRecord.Attributes as Tap.AccountService.User.UserAttributes).email;
            relatedRecord = relatedWorkflow.rows.find(row => row.rowProperties.find(property => property.key === TapUserProperties.email)?.value === email);
            break;
          case RecordType.LOCATION:
            relatedRecord = relatedWorkflow.rows.find(row => row.id === accountRecord.Attributes.id);
            break;
        }

        if (!relatedRecord) {
          return;
        }

        updateCallbackRecord(error, accountRecord, relatedRecord);

      } catch (error) {
        console.log(error);
      }

    },

    setTypeFilter: (state, action: PayloadAction<{ workflowId: string, typeFilter: string }>) => {
      const { workflowId, typeFilter } = action.payload;
      const workflows = state.workflows.filter(w => w.id === workflowId);

      if (!workflows) {
        return;
      }

      for (const workflow of workflows) {
        workflow.typeFilter = typeFilter;
      }
    },

    setSearchText: (state, action: PayloadAction<{ workflowId: string, searchText: string }>) => {
      const { workflowId, searchText } = action.payload;
      const workflows = state.workflows.filter(w => w.id === workflowId);

      if (!workflows) {
        return;
      }

      for (const workflow of workflows) {
        workflow.searchText = searchText;
      }
    }
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region [SITE SUBSCRIPTION]
      .addCase(addSiteSubscriptionRequest.fulfilled, (state, action) => {
        const { workflowId, siteId } = action.payload;
        const workflow = state.workflows.find((w) => w.id === workflowId);
        if (!workflow) {

          state.error = 'Workflow not found';
          state.status = 'failed';
          return;

        }
        workflow.siteSubscriptions.push(siteId);
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.status = "success";
        state.workflows[index] = workflow;
      })
      //#endregion

      //#region [SITE UNSUBSCRIPTION]
      //TODO update to add account ids
      .addCase(removeSiteSubscriptionRequest.fulfilled, (state, action) => {
        const { siteId, workflowId } = action.payload;
        const workflow = state.workflows.find(
          (wf) => wf.id === workflowId
        );

        if (!workflow) {
          state.status = "failed";
          state.error = "Targeted workflow not found";
          return;
        }

        const siteIndex = workflow.siteSubscriptions.indexOf(siteId);

        if (siteIndex < 0) {
          state.status = "failed";
          state.error = "Site does not exist in workflow site subscriptions";
          return;
        }

        workflow.siteSubscriptions.splice(siteIndex, 1);
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.status = "success";
        state.workflows[index] = workflow;
      })
      //#endregion

      //#region [ROLE SUBSCRIPTION]
      .addCase(addRoleSubscriptionRequest.fulfilled, (state, action) => {
        const { workflowId, roleId } = action.payload;
        const workflow = state.workflows.find((w) => w.id === workflowId);
        if (!workflow) {

          state.error = 'Workflow not found';
          state.status = 'failed';
          return;

        }
        workflow.roleSubscriptions.push(roleId);
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.status = "success";
        state.workflows[index] = workflow;
      })
      //#endregion

      //#region [ROLE UNSUBSCRIPTION]
      .addCase(removeRoleSubscriptionRequest.fulfilled, (state, action) => {
        const { roleId, workflowId } = action.payload;
        const workflow = state.workflows.find(
          (wf) => wf.id === workflowId
        );

        if (!workflow) {
          state.status = "failed";
          state.error = "Targeted workflow not found";
          return;
        }

        const roleIndex = workflow.siteSubscriptions.indexOf(roleId);

        if (roleIndex < 0) {
          state.status = "failed";
          state.error = "Role does not exist in workflow role subscriptions";
          return;
        }

        workflow.siteSubscriptions.splice(roleIndex, 1);
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.status = "success";
        state.workflows[index] = workflow;
      })
      //#endregion

      //#region [WORKFLOW CUD]
      // [WORKFLOW CREATE]
      .addCase(createWorkflowRequest.fulfilled, (state, action) => {
        state.status = "success";
        state.workflows.push(action.payload);
      })
      .addCase(updateWorkflowStatusesRequest.fulfilled, (state, action) => {
        const workflowIndex = state.workflows.findIndex((wf) => wf.id === action.payload.workflowId && wf.accountId === action.payload.accountId);

        if (workflowIndex === -1) {
          state.status = "failed";
          state.error = "Workflow to update not found";
          return;
        }

        state.status = "success";
        state.workflows[workflowIndex].statuses = action.payload.statuses;

      })
      .addCase(updateWorkflowRequest.fulfilled, (state, action) => {
        const worflow = action.payload;
        const index = state.workflows.findIndex((wf) => wf.id === worflow.id && wf.accountId === worflow.accountId);

        if (index === -1) {
          state.status = "failed";
          state.error = "Workflow to update not found";
          return;
        }

        state.status = "success";
        state.workflows[index] = action.payload;
      })

      // [DELETE WORKFLOW]
      .addCase(deleteWorkflowRequest.fulfilled, (state, action) => {
        state.status = 'success';

        const index = state.workflows.findIndex(
          (wf) => wf.id === action.payload.workflowId
        );

        if (index < 0) {
          return;
        }
        state.workflows.splice(index, 1);
      })

      // [WORKFLOW REQUEST CREATE]
      .addCase(createWorkflowRecordRequest.fulfilled, (state, action) => {
        const { workflowId, record } = action.payload;
        const workflow = state.workflows.find(wf => wf.id === workflowId && wf.accountId === record.accountId);

        if (!workflow) {
          state.error = 'Workflow not found';
          state.status = 'failed';
          return;
        }

        workflow.rows.push(record);
      })

      // [WORKFLOW RECORD UPDATE]
      .addCase(updateWorkflowRecordRequest.fulfilled, (state, action) => {
        const { workflowId, record } = action.payload;
        const workflow = state.workflows.find(wf => wf.id === workflowId && wf.accountId === record.accountId);

        if (!workflow) {
          state.error = 'Workflow not found';
          state.status = 'failed';
          return;
        }

        const recordToUpdateIndex = workflow.rows.findIndex(wr => wr.id === record.id);
        if (recordToUpdateIndex < 0) {
          state.error = 'Record to update not found';
          state.status = 'failed';
          return;
        }

        workflow.rows[recordToUpdateIndex] = record;
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.workflows[index] = workflow;
        state.status = 'success';

      })

      // [WORKFLOW RECORD DELETE]
      .addCase(deleteWorkflowRecordRequest.fulfilled, (state, action) => {
        const { workflowId, recordId } = action.payload;
        const workflow = state.workflows.find(wf => wf.id === workflowId);

        if (!workflow) { //TODO: test cases
          state.error = 'Workflow not found';
          state.status = 'failed';
          return;
        }

        const recordToDeleteIndex = workflow.rows.findIndex(wr => wr.id === recordId);
        if (recordToDeleteIndex < 0) {
          state.error = 'Record to delete not found';
          state.status = 'failed';
          return;
        }

        workflow.rows.splice(recordToDeleteIndex, 1);
        const index = state.workflows.findIndex(wf => wf.id === workflowId);
        state.workflows[index] = workflow;
        state.status = 'success';

      })
      //#endregion
      .addCase(getWorkflowRecords.fulfilled, (state, action) => {
        const { accountId, transformedRows, workflowId, startDate, endDate } = action.payload;

        const workflow = state.workflows.find(wf => wf.id === workflowId && wf.accountId === accountId);

        if (!workflow) {
          return;
        }

        workflow.rows = transformedRows;
        workflow.startDate = startDate;
        workflow.endDate = endDate
      }).addCase(acceptInvitation.fulfilled, (state, action) => {
        const { accountId, email } = action.payload;
        const workflow = state.workflows.find(wf => wf.id === TapTables.app_users && wf.accountId === accountId);

        if (!workflow) {
          return;
        }

        const user = workflow.rows.find(row => row.id === email);

        if (!user) {
          return;
        }

        WorkflowHelpers.overwriteRowProperty(user, TapUserProperties.invitationAccepted, true);
      }).addCase(createOrUpdateLocation.fulfilled, (state, action ) => {

        const { accountId, locationId, attributes, createdBy, deleted } = action.payload;

        const workflow = state.workflows.find(wf => wf.id === TapTables.sites && wf.accountId === accountId);

        if (!workflow) {
          return;
        }

        const location = workflow.rows.find(row => row.id === locationId);

        if (!location) {
          workflow.rows.push({
            accountId,
            createdBy,
            deleted,
            id: attributes.id,
            lastSynced: 0,
            version: 1,
            sync: 'pending_create',
            rowProperties: [
              {
                key: TapLocationProperties.name,
                value: attributes.name
              },
              {
                key: TapLocationProperties.address,
                value: attributes.address
              },
              {
                key: TapLocationProperties.id,
                value: attributes.id
              }
            ]

          });

          return;
        }

        WorkflowHelpers.overwriteRowProperty(location, TapLocationProperties.name, attributes.name);
        WorkflowHelpers.overwriteRowProperty(location, TapLocationProperties.address, attributes.address);

      });
  },
});

const isWorkflowRecordComplete = (workflow: Workflow, row: TapRow) => {
  const requiredFields = workflow.fields.filter(field => field.fieldProperties.isRequired);
  return requiredFields.every(field => row.rowProperties.some(property => property.key === field.id))
}

export const {setSite , syncWorkflows, setSearchText, callbackOnAccountRecord, setTypeFilter, callbackOnAddSiteSubscription, callbackOnSiteUnsubscription, callbackOnRoleSubscription, callbackOnRoleUnsubscription } = workflowSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectWorkflows = (state: RootState) => state.workflow.workflows;
export const selectActiveSite = (state: RootState) => state.workflow.site;
export const selectWorkflow = (state: RootState, workflowId: string, accountId: string) => state.workflow.workflows.find(flow => flow.id === workflowId && flow.accountId === accountId);

export default workflowSlice.reducer;

function updateCallbackRecord(error: any, accountRecord: AccountRecord, record: WorkItemState) {
  if (error) {
    if (record.sync === "synced" || record.sync === "pending_update") {
      record.sync = "failed_updating";
    } else {
      record.sync = "failed_creating";
    }

    return;
  }

  record.sync = "synced";
  record.deleted = accountRecord._deleted;
  record.version = accountRecord._version;
  record.lastSynced = accountRecord._lastChangedAt;
}

function updateVisitors(records: AccountRecord[], state: Draft<WorkflowState>){
  const visitors = records.filter(record => record.Type === RecordType.VISITOR);
  visitors.forEach(visitor => {

    const accountId = visitor.PK.split('#')[1];
    const visitorAttributesJSON = JSON.parse(visitor.Attributes);
    const visitorAttributes = new Tap.AccountService.WorkflowRecord.VisitorAttributes(visitorAttributesJSON);

    const workflowToUpdate = state.workflows.find(workflow => workflow.accountId === accountId && workflow.id === TapTables.visitors);

    if (!workflowToUpdate) {
      return;
    }

    const tapRow = workflowToUpdate.rows.find(row => row.id === visitorAttributes.id);

    if (!tapRow) {
      workflowToUpdate.rows.push({
        accountId,
        id: visitorAttributes.id,
        rowProperties: [
          ...visitorAttributes.rowProperties
        ],
        sync: 'synced',
        createdBy: visitor._createdBy,
        deleted: visitor._deleted,
        lastSynced: visitor._lastChangedAt,
        version: visitor._version
      });

      return;
    }

    tapRow.rowProperties = visitorAttributes.rowProperties
    tapRow.lastSynced = visitor._lastChangedAt;
    tapRow.deleted = visitor._deleted;
    tapRow.createdBy = visitor._createdBy;
    tapRow.version = visitor._version;
    tapRow.sync = 'synced';
  });
}

function updateUsers(records: AccountRecord[], state: Draft<WorkflowState>) {
  const users = records.filter(record => record.Type === RecordType.USER);
  users.forEach(user => {

    const accountId = user.PK.split('#')[1];
    const userId = user.SK.split('#')[1];
    const userAttributesJSON = JSON.parse(user.Attributes);
    const userAttributes = new Tap.AccountService.User.UserAttributes(userAttributesJSON);

    const workflowToUpdate = state.workflows.find(workflow => workflow.accountId === accountId && workflow.id === TapTables.app_users);

    if (!workflowToUpdate) {
      return;
    }

    const tapRow = workflowToUpdate.rows.find(row => row.id === userId);

    if (!tapRow) {
      workflowToUpdate.rows.push({
        accountId,
        id: userId,
        rowProperties: [
          {
            key: TapUserProperties.name,
            value: `${userAttributes.firstName} ${userAttributes.lastName}`,
          },
          //*..............To be combined to Name field
          {
            key: TapUserProperties.firstName,
            value: userAttributes.firstName
          },
          {
            key: TapUserProperties.lastName,
            value: userAttributes.lastName
          },
          //*............................
          {
            key: TapUserProperties.email,
            value: userAttributes.email
          },
          {
            key: TapUserProperties.profilePicture,
            value: userAttributes.profilePicture
          },
          {
            key: TapUserProperties.roleIds,
            value: userAttributes.roleIds
          },
          {
            key: TapUserProperties.locationIds,
            value: userAttributes.locationIds
          },
          {
            key: TapUserProperties.invitationAccepted,
            value: userAttributes.invitationDetails.invitationAccepted
          },
          {
            key: TapUserProperties.linkExpiration,
            value: userAttributes.invitationDetails.linkExpiration
          },
          {
            key: TapUserProperties.acceptedBy,
            value: userAttributes.invitationDetails.acceptedBy
          }
        ],
        sync: 'synced',
        createdBy: user._createdBy,
        deleted: user._deleted,
        lastSynced: user._lastChangedAt,
        version: user._version
      });

      return;
    }

    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.roleIds, userAttributes.roleIds);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.locationIds, userAttributes.locationIds);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.invitationAccepted, userAttributes.invitationDetails.invitationAccepted);
    WorkflowHelpers.overwriteRowProperty(tapRow, UserFields.profilePicture, userAttributes.profilePicture);
    tapRow.lastSynced = user._lastChangedAt;
    tapRow.deleted = user._deleted;
    tapRow.createdBy = user._createdBy;
    tapRow.version = user._version;

    tapRow.sync = 'synced';
  });
}

function updateCompanionAppUsers(records: AccountRecord[], state: Draft<WorkflowState>) {
  const users = records.filter(record => record.Type === RecordType.COMPANION_APP_USER);
  users.forEach(user => {

    const accountId = user.PK.split('#')[1];
    const userId = user.SK.split('#')[1];
    const userAttributesJSON = JSON.parse(user.Attributes);
    const userAttributes = new Tap.AccountService.User.CompanionAppUserAttributes(userAttributesJSON);

    const workflowToUpdate = state.workflows.find(workflow => workflow.accountId === accountId && workflow.id === TapTables.companion_app);

    if (!workflowToUpdate) {
      return;
    }

    const tapRow = workflowToUpdate.rows.find(row => row.id === userId);

    if (!tapRow) {
      workflowToUpdate.rows.push({
        accountId,
        id: userId,
        rowProperties: [
          {
            key: CompanionAppUserProperties.name,
            value: userAttributes.name
          },
          {
            key: CompanionAppUserProperties.unit,
            value: userAttributes.unit
          },
          {
            key: CompanionAppUserProperties.username,
            value: userAttributes.username
          },
          {
            key: CompanionAppUserProperties.email,
            value: userAttributes.email
          },
          {
            key: CompanionAppUserProperties.address,
            value: userAttributes.address
          },
          {
            key: CompanionAppUserProperties.phone,
            value: userAttributes.phone
          },
          {
            key: CompanionAppUserProperties.permissions,
            value: userAttributes.permissions,
          },
          {
            key: CompanionAppUserProperties.houseMates,
            value: userAttributes.houseMates
          },
          {
            key: CompanionAppUserProperties.profilePicture,
            value: userAttributes.profilePicture
          },
          {
            key: CompanionAppUserProperties.roleIds,
            value: userAttributes.roleIds
          },
          {
            key: CompanionAppUserProperties.locationIds,
            value: userAttributes.locationIds
          },
          {
            key: CompanionAppUserProperties.invitationAccepted,
            value: userAttributes.invitationDetails.invitationAccepted
          },
          {
            key: CompanionAppUserProperties.linkExpiration,
            value: userAttributes.invitationDetails.linkExpiration
          },
          {
            key: CompanionAppUserProperties.acceptedBy,
            value: userAttributes.invitationDetails.acceptedBy
          }
        ],
        sync: 'synced',
        createdBy: user._createdBy,
        deleted: user._deleted,
        lastSynced: user._lastChangedAt,
        version: user._version
      });

      return;
    }

    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.roleIds, userAttributes.roleIds);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.locationIds, userAttributes.locationIds);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapUserProperties.invitationAccepted, userAttributes.invitationDetails.invitationAccepted);
    WorkflowHelpers.overwriteRowProperty(tapRow, UserFields.profilePicture, userAttributes.profilePicture);
    tapRow.lastSynced = user._lastChangedAt;
    tapRow.deleted = user._deleted;
    tapRow.createdBy = user._createdBy;
    tapRow.version = user._version;

    tapRow.sync = 'synced';
  });
}


function updateRoles(records: AccountRecord[], state: Draft<WorkflowState>) {
  const roles = records.filter(record => record.Type === RecordType.ROLE);
  roles.forEach(role => {

    const accountId = role.PK.split('#')[1];
    const roleAttributesJSON = JSON.parse(role.Attributes);
    const roleAttributes = new Tap.AccountService.Role.RoleAttributes(roleAttributesJSON);

    const workflowToUpdate = state.workflows.find(workflow => workflow.accountId === accountId && workflow.id === TapTables.roles);

    if (!workflowToUpdate) {
      return;
    }

    const tapRow = workflowToUpdate.rows.find(row => row.id === roleAttributes.id);

    if (!tapRow) {
      workflowToUpdate.rows.push({
        accountId,
        id: roleAttributes.id,
        sync: 'synced',
        rowProperties: [
          {
            key: TapRoleProperties.description,
            value: roleAttributes.description
          },
          {
            key: TapRoleProperties.name,
            value: roleAttributes.name
          },
          {
            key: TapRoleProperties.permissions,
            value: roleAttributes.permissions
          }
        ],
        createdBy: role._createdBy,
        deleted: role._deleted,
        lastSynced: role._lastChangedAt,
        version: role._version
      });

      return;
    }

    WorkflowHelpers.overwriteRowProperty(tapRow, TapRoleProperties.description, roleAttributes.description);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapRoleProperties.name, roleAttributes.name);
    WorkflowHelpers.overwriteRowProperty(tapRow, TapRoleProperties.permissions, roleAttributes.permissions);
    tapRow.lastSynced = role._lastChangedAt;
    tapRow.deleted = role._deleted;
    tapRow.createdBy = role._createdBy;
    tapRow.version = role._version;
    tapRow.sync = 'synced';

  });
}

function updateLocations(records: AccountRecord[], state: Draft<WorkflowState>) {
  const locations = records.filter(record => record.Type === RecordType.LOCATION);
  locations.forEach(location => {

    const accountId = location.PK.split('#')[1];
    const locationAttributesJSON = JSON.parse(location.Attributes);
    const locationAttributes = new Tap.AccountService.List.LocationAttributes(locationAttributesJSON);

    const workflowToUpdate = state.workflows.find(workflow => workflow.accountId === accountId && workflow.id === TapTables.sites);

    if (!workflowToUpdate) {
      return;
    }

    const tapRow = workflowToUpdate.rows.find(row => row.id === locationAttributes.id);

    if (!tapRow) {
      workflowToUpdate.rows.push({
        accountId,
        id: locationAttributes.id,
        rowProperties: [
          {
            key: TapSiteProperties.name,
            value: locationAttributes.name
          }
        ],
        sync: 'synced',
        createdBy: location._createdBy,
        deleted: location._deleted,
        lastSynced: location._lastChangedAt,
        version: location._version
      });

      return;
    }

    WorkflowHelpers.overwriteRowProperty(tapRow, TapSiteProperties.name, locationAttributes.name);
    tapRow.lastSynced = location._lastChangedAt;
    tapRow.deleted = location._deleted;
    tapRow.createdBy = location._createdBy;
    tapRow.version = location._version;
    tapRow.sync = 'synced';
  });
}

function updateWorkflows(records: AccountRecord[], state: Draft<WorkflowState>) {
  const workflowUpdates = records.filter(record => record.Type === RecordType.WORKFLOW);
  workflowUpdates.forEach(workflow => {
    const accountId = workflow.PK.split('#')[1];
    const attributesJson = JSON.parse(workflow.Attributes);

    const updatedWorkflow = new Tap.AccountService.Workflow.WorkflowAttributes(attributesJson);

    const workflowForUpdate = state.workflows.find(localWorkflow => localWorkflow.accountId === accountId && localWorkflow.id === updatedWorkflow.id);

    if (!workflowForUpdate) {
      state.workflows.push({
        accountId,
        ...updatedWorkflow,
        rows: [],
        searchText: '',
        statuses: [],
        typeFilter: '',
        lastSynced: workflow._lastChangedAt,
        createdBy: workflow._createdBy,
        deleted: workflow._deleted,
        version: workflow._version,
        sync: 'synced'
      });

      return;
    }

    workflowForUpdate.fields = updatedWorkflow.fields;
    workflowForUpdate.name = updatedWorkflow.name;
    workflowForUpdate.userSubscriptions = updatedWorkflow.userSubscriptions;
    workflowForUpdate.siteSubscriptions = updatedWorkflow.siteSubscriptions;
    workflowForUpdate.roleSubscriptions = updatedWorkflow.roleSubscriptions;
    workflowForUpdate.sync = "synced";
    workflowForUpdate.lastSynced = workflow._lastChangedAt;
    workflowForUpdate.deleted = workflow._deleted;
    workflowForUpdate.createdBy = workflow._createdBy;
    workflowForUpdate.version = workflow._version;
  });
}

function transformWorkflowRecords(records: AccountRecord[], accountId: string) {
  const transformedRecords: TapRow[] = [];

  records.forEach(record => {

    const recordAttributesJson = JSON.parse(record.Attributes);
    const recordAttributes = new Tap.AccountService.WorkflowRecord.RecordAttributes(recordAttributesJson);
    transformedRecords.push({
      accountId,
      id: recordAttributes.id,
      rowProperties: recordAttributes.rowProperties,
      createdBy: record._createdBy,
      deleted: record._deleted,
      lastSynced: record._lastChangedAt,
      version: record._version,
      sync: 'synced'
    });

  });

  return transformedRecords;

}

