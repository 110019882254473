/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const httpApiUrl = process.env.REACT_APP_REST_API_URL;
const gqlApiUrl = process.env.REACT_APP_GQL_API_URL;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const cognitoIdPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const region = process.env.REACT_APP_REGION;
const appStage = process.env.REACT_APP_STAGE_NAME;
const siteUrl = process.env.REACT_APP_SITE_URL;
const oauthDomain = `tap-sign-in-${appStage}.auth.${region}.amazoncognito.com`;
const tapBucketName = process.env.REACT_APP_TAPBUCKET;
const awsmobile = {
    "aws_project_region": region,//"us-east-2",
    "aws_cognito_identity_pool_id": cognitoIdPoolId,//"us-east-2:09adf6cc-5430-439f-ac88-d38f800dbb69",
    "aws_cognito_region": region,//"us-east-2",
    "aws_user_pools_id": userPoolId,//"us-east-2_WPYLuSXGS",
    "aws_user_pools_web_client_id": userPoolClientId,//"65j98lfsvar4aipc8259kj9kqg",
    "aws_appsync_graphqlEndpoint": gqlApiUrl,//"https://7fseq5pinzh7zb5g6kabpfnqd4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": region,//"us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": tapBucketName,
    "aws_user_files_s3_bucket_region": region,
    "oauth": {
        "domain": oauthDomain,//"tap-sign-in-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": `${siteUrl}/admin`,
        "redirectSignOut": `${siteUrl}/auth/signin`,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
