import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
//'@aws-amplify/auth/lib/types'
import { MixpanelEvent, track } from "../helpers/analytics/mixpanel-helper";

export const AmplifyAuthError = {
    UserNotConfirmedException: {
        code: "UserNotConfirmedException",
        message: "User is not confirmed."
    },
    UsernameExistsException: {
        code: "UsernameExistsException",
        message: "An account with the given email already exists."
    },
    UserNotFoundException: {
        code: "UserNotFoundException",
        message: ["User does not exist.", "Username/client id combination not found."]
    },
    UserLambdaValidationException: {
        code: "UserLambdaValidationException",
        message: "PreSignUp failed with error Invalid sign up."
    },
    InvalidParameterException:{
        code: "InvalidParameterException",
        message: ["Cannot reset password for the user as there is no registered/verified email or phone_number", "Missing required parameter Session"]
    },
    LimitExceededException: {
        code: "LimitExceededException",
        message: "Attempt limit exceeded, please try after some time."
    },
    CodeMismatchException: {
        code: "CodeMismatchException",
        message: "Invalid verification code provided, please try again."
    },
    NotAuthorizedException: {
        code: "NotAuthorizedException",
        message: ["User cannot be confirmed. Current status is CONFIRMED"]
    }
}

export type CognitoUserAttribute = {
    sub: string;
    email_verified: boolean;
    phone_number_verified: boolean;
    'custom:isCompanyAccount': string;
    given_name: string;
    family_name: string;
    email: string;
}
export type CognitoUser = {
    username: string;
    keyPrefix: string;
    userDataKey: string;
    attributes: CognitoUserAttribute;
    preferedMFA: string;
    authenticationFlowType: string;
}

export class UserAttributes{
    email?: string;
    phone_number?: string;
    given_name?: string;
    family_name?: string;
    isCompany?: string;
    companyName?: string;

    constructor(email?: string, phone_number?: string, given_name?: string, family_name?: string ){
        this.email = email;
        this.phone_number = phone_number;
        this.given_name = given_name;
        this.family_name = family_name;
    }   
}

export type CustomAttributes = {
    isCompanyAccount: boolean
}

export type SignUpData = {
    username: string;
    password: string;
    attributes?: UserAttributes
}

export type CodeDeliveryDetails = {
    AttributeName: string;//"email"
    DeliveryMedium: string;//Valid Values: SMS | EMAIL
    Destination: string;//The destination for the code delivery details eg. "b***@g***.com"
}

export type ForgotPasswordResult = {
    CodeDeliveryDetails: CodeDeliveryDetails;
}

export const getJwtToken = async ()  => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
}

export const isSignedIn = async () => {
    try{
        const user = await Auth.currentUserInfo();
        return !(user === undefined || user === null);
    }catch(error){
        console.log(error);
    }
    
}

export const currentAuthenticatedUser = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user;
    } catch (error) {
        console.log(`Error getting signed in user - ${error}`);
        throw error;
    }
}

export const signIn = async (username: string, password: string) =>  {
    try {
        let user: CognitoUser = await Auth.signIn({
            username: username,
            password: password
        });
        track({
            event: MixpanelEvent.SIGN_IN,
            props: {
                'username': username,
            }
        })
        return user;
    } catch (error) {
        console.log(`Error signing in - ${error}`);
        throw error;
    }
}

export const confirmSignIn = async (username: string, code: string) => {
    try {
        const confirmResult = await Auth.confirmSignIn(username, code);
        console.log('successfully confirmed signed in');
        return confirmResult;
    } catch (error) {
        console.log(`Error confirming sign up - ${ error }`);
        throw error;
    }
}

export const signUp = async (signUpData: SignUpData) => {
    try {
        const { username, password, attributes:{email, phone_number, given_name, family_name, isCompany, companyName,} = new UserAttributes() } = signUpData || {};
        const signUpResult = await Auth.signUp({
            username: username,
            password: password,
            attributes: {
                email: email,
                phone_number: phone_number,
                given_name: given_name,
                family_name: family_name,
                'custom:isCompanyAccount': isCompany,
                'custom:companyName': companyName,
            }
        });
        track({event: MixpanelEvent.SIGN_UP, props:{
            'username': username,
            'givenName': given_name,
            'familyName': family_name,
            'email': email ?? '',
            'phoneNumber': phone_number ?? '',
            'isCompany': isCompany,
            'companyName:': companyName ?? '',
        } });
        console.log('Successfully signed up');
        return signUpResult;
    } catch (error) {
        console.log(`Error signing up: ${ error }`);
        throw error;
    }
}

export const confirmSignUp = async (username: string, confirmationCode: string) => {
    try {
        const confirmSignUpResult = await Auth.confirmSignUp(username, confirmationCode);
        return confirmSignUpResult;
    } catch (error) {
        console.log(`Error confirming sign up - ${ error }`);
        throw error;
    }
}

export const resendConfirmationCode = async (username: string) => {
    try {
        const result = await Auth.resendSignUp(username);
        return result;
    } catch (err) {
        console.log('error resending code: ', err);
        throw err;
    }
}

export const signOut = async () => {
    try {
        await Auth.signOut();
        return true;
    } catch (error) {
        console.log('error signing out: ', error);
        throw error;
    }
}

export const globalSignOut = async () => {
    try {
        const signOutResult = await Auth.signOut({ global: true });
        return signOutResult;
    } catch (error) {
        console.log('error signing out: ', error);
        return error;
    }
}

// STEP. 1
export const forgotPassword = async (username: string) => {
    try {
        const result: ForgotPasswordResult = await Auth.forgotPassword(username);
        return result;
    } catch (error) {
        console.log('error signing out: ', error);
        throw error;
    }
}

//STEP. 2
export const forgotPasswordSubmit = async (username: string, code: string, new_password: string) => {
    try {
        await Auth.forgotPasswordSubmit(username, code, new_password);
    } catch (error) {
        console.log('error signing out: ', error);
        throw error;
    }
}

// STEP. 3
export const completeNewPassword = async (username: string, newPassword: string) => {
    try {

        const user  = await Auth.signIn(username, newPassword);
        if(user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            await Auth.completeNewPassword(user, newPassword, 
                requiredAttributes
            );
        }
        return user;
    } catch (error) {
        throw error;
    }
}

export const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const result = await Auth.changePassword(user, oldPassword, newPassword);
        return result;
    } catch (error) {
        throw error;
    }
}

export const signInWithFacebook = async () => {
    try {
        const credentials = await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
        return credentials;
    } catch (error) {
        throw error;
    }
}

