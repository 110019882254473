import { ResponseEvent } from '../../../../models/response-event';
import { PersistedQueue } from '../../../persisted-queue';
const KEY = "RESPONSEQUEUE";
let persistedQueue: PersistedQueue;

const queue = async () => {
    if(!persistedQueue){
        persistedQueue = await PersistedQueue.createAsync(KEY);
    }

    return persistedQueue;
}


const enqueue = async (item: ResponseEvent): Promise<boolean> => {
    return (await queue()).enqueue(item);
}

const dequeue = async () => {
    return (await queue()).dequeue() as Promise<ResponseEvent | undefined>;
}



const peek = async () => {
    return (await queue()).peek() as Promise<ResponseEvent | undefined>;
}

const length = async () => {
    return (await queue()).length();
}

const clear = async () => {
    return (await queue()).clear();
}

const responseEventQueue = {
    enqueue,
    peek,
    dequeue,
    length,
    clear
};

export default responseEventQueue;