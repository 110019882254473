import React from 'react';
import AcceptInvitation from '../components/Invitation/AcceptInvitation/AcceptInvitation';

const  Invitation : React.FunctionComponent<{}> = () => {
        return (
            <div>
               <AcceptInvitation/>
            </div>
        )

}

export default Invitation;
