import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaymentPlanSubscription } from "../../../interfaces/payment-slice/payment-plan-subscription";
import { PaymentPlan } from "../../../models/PaymentPlan";
import { RootState } from "../../store";
import { fetchAppPaymentPlans, subscribeToPaymentPlan } from "./payment-api";

export interface PaymentState {
  paymentPlans: PaymentPlan[];
  subscribedPlan: PaymentPlan | undefined;
  error: string | undefined;
  fetchStatus: "idle" | "loading" | "failed" | "success";
  subcribtionStatus: 'idle' | 'processing' | 'success' | 'failed';
}

export const initialPaymentState: PaymentState = {
  paymentPlans: [],
  subscribedPlan: undefined,
  error: undefined,
  fetchStatus: "idle",
  subcribtionStatus: 'idle'
};

export const fetchPaymentPlans = createAsyncThunk(
  "payment/paymentplans",
  async () => {
    const response = await fetchAppPaymentPlans();
    return response;
  }
);

export const subscribePaymentPlan = createAsyncThunk(
  'payment/plan-subscription',
  async (planSubscription: PaymentPlanSubscription) => {
    const response = await subscribeToPaymentPlan(planSubscription);
    if(response) {
      return response;
    }
    throw new Error('Failed to subscribe to plan, try again.');
  }
)

export const paymentSlice = createSlice({
  name: "payment",
  initialState: initialPaymentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentPlans.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(fetchPaymentPlans.fulfilled, (state, action) => {
        state.fetchStatus = "idle";
        state.paymentPlans = action.payload.data ?? [];
      })
      .addCase(fetchPaymentPlans.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.error = action.error.message;
      })

      .addCase(subscribePaymentPlan.pending, (state) => {
        state.subcribtionStatus = 'processing';
      })
      .addCase(subscribePaymentPlan.fulfilled, (state, action) => {
        state.subcribtionStatus = 'success';
        state.subscribedPlan = action.payload.data;
      })
      .addCase(subscribePaymentPlan.rejected, (state, action) => {
        state.subcribtionStatus = 'failed';
        state.error = action.error.message;
      })
  },
});

export const fetchAllPaymentPlans = (state: RootState) => state.payment.paymentPlans;
export default paymentSlice.reducer;