export const BLANK_LIST_ID = '__';
export const BLANK_USER_ID = '__';
export const MISSING_REQUIRED_FIELD = 'missing-required-field';

export enum UserFields {
    name = 'name',
    profilePicture = 'profilePicture'
}

export enum ContactProperties {
    email = 'email',
    phone = 'phone'
}


export enum TapUserProperties{
    name = 'name',
    firstName = 'firstName',//*to be combined to name
    lastName = 'lastName',//*to be combined to name
    email = 'email',
    profilePicture = 'profilePicture',
    locationIds = 'site',
    roleIds = 'role',
    invitationAccepted = 'invitationAccepted',
    linkExpiration = 'linkExpiration',
    acceptedBy = 'acceptedBy',
    unit = 'unit',
    address = 'address',
    houseMates = 'houseMates',
    phone = 'phone',
    inviteType = 'inviteType',
    primaryRecordTrigger =  'primaryRecordTrigger',
}

// export enum CompanionAppUserProperties{
//     name = 'name',
//     phone = 'phone',
//     site = 'site',
//     unit = 'unit',
//     username = 'username',
//     address = 'address',
//     houseMates = 'unit_mates'
// }

export enum CompanionAppUserProperties{
    name = 'name',
    phone = 'phone',
    site = 'site',
    unit = 'unit',
    username = 'username',
    address = 'address',//*
    contact = 'contact',//*used on frontend only for custom view
    houseMates = 'unit_mates',//'unit_mates', houseMates
    //*added attributes
    email = 'email',
    roleIds = 'role',
    locationIds = 'site',
    profilePicture = 'profilePicture',
    invitationDetails = 'invitationDetails',
    invitationAccepted = 'invitationAccepted',
    linkExpiration = 'linkExpiration',
    acceptedBy = 'acceptedBy',
    inviteType = 'inviteType',
    permissions = 'permissions',
}

export enum TapRoleProperties{
    name = 'role',
    description = 'description',
    permissions = 'permission'
}

export enum TapSiteProperties{
    name = 'name'
}

export enum TapVisitorProperties{
    email = 'email',
    host = 'host',
    date = 'date',
    name = 'name',
    location = 'location',
    userType = 'userType',
    profilePicture = 'profilePicture',
    signIn = 'signIn',
    signOut = 'signOut'
}

export enum TapInviteProperties {
    name = 'name',
    contact = 'contact',
    host = 'host',
    location = 'location',
    inviteDate = 'date',
    timeframe = 'timeframe',
    inviteSent = 'inviteSent',
    signIn = 'signIn',
    otp = 'otp',
    timeZone = 'timeZone',
    dateCreated = 'dateCreated'
}

export enum TapLocationProperties {
    name = 'name',
    address = 'address',
    id = 'id'
}