import React, { useState } from 'react';
import { Tap } from 'tap-types';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import './AcceptInvitation.css';
import FloatingLogo from '../../FloatingLogo/FloatingLogo';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { loadUser, selectAuthState } from '../../../redux/slices/auth-slice/auth-slice';
import AuthFlowWrapper from '../../Auth/AuthFlowWrapper/AuthFlowWrapper';
import { toast } from 'react-toastify';
import { acceptInvitation } from '../../../redux/slices/workflow-slice/workflow-slice';

const AcceptInvitation: React.FunctionComponent<{}> = () => {

    const params = new URLSearchParams(window.location.search);
    const [redirectToRoot, setRedirectToRoot] = useState<boolean>(false);
    const [invitation] = useState<Tap.DynamicLinkParams>({
        accountId: params.get('accountId') ?? '',
        companyName: params.get('companyName') ?? '',
        email: params.get('email') ?? '',
        linkExpiration: Number(params.get('linkExpiration'))
    });
    const [isAcceptingInvite, setIsAcceptingInvite] = useState(false);
    const dispatch = useAppDispatch();
    const history = useHistory();


    const authState = useAppSelector(selectAuthState);


    const acceptInvite = () => {

        setIsAcceptingInvite(true);
        dispatch(acceptInvitation(invitation)).then((response) => {
            if (response.meta.requestStatus === 'rejected') {
                toast.error('Failed to accept invitation. Try again');
                setIsAcceptingInvite(false);
            }

            dispatch(loadUser()).then(() => {
                toast.success('Account added successfully.');
                history.push('/');
            }).catch(() => {
                toast.success('Account added successfully');
                toast.warning('Error updating users. You may have to sign out and sign in back in to see changes');
                history.push('/');
            });
        });
    }


    if (!invitation.accountId || redirectToRoot || !invitation.companyName || !invitation.email || isNaN(invitation.linkExpiration)) {
        return <Redirect to='/' />
    }

    if (invitation.linkExpiration < Date.now()) {
        return <><Modal isOpen={true} backdrop={'static'} className="invitation__expired invitation__modal">
            <ModalHeader className={"invitation__header"}>
                <div className="">
                    <span>Invitation Expired</span>
                </div>
            </ModalHeader>
            <ModalBody className={"modal__body"}>
                <div>
                    <span >
                        This invitation, to join <b> {invitation.companyName} </b>, has expired. Contact the admin to generate a new invitation
                    </span>
                </div>
            </ModalBody>
            <ModalFooter className={"invitation__footer"}>
                <Row>
                    <Button color="primary" onClick={() => setRedirectToRoot(true)}>Okay</Button>
                </Row>

            </ModalFooter>

        </Modal>
            <FloatingLogo />
        </>
    }

    if (!authState.user) {
        return <AuthFlowWrapper />
    }

    return (
        <><Modal isOpen={true} backdrop={'static'} className="invitation__modal">
            <ModalHeader className={"invitation__header"}>
                <div className="">
                    <span>Accept Invitation</span>
                </div>
            </ModalHeader>
            <ModalBody className={"modal__body"}>
                <div>
                    <span >
                        Are you sure you want to add <b>{invitation.email}</b> for <b>{invitation.companyName}</b> to this account ({authState.user.phone ?? authState.user.email})?
                    </span>
                </div>
            </ModalBody>
            <ModalFooter className={"invitation__footer"}>
                <Row>
                    <Button data-cy="cancel-invite" color="secondary" onClick={() => setRedirectToRoot(true)}>Cancel</Button>
                    <Button data-cy="accept-invite" disabled={isAcceptingInvite} id="button__accept" onClick={() => acceptInvite()}>
                        {
                            isAcceptingInvite ?
                                <> <Spinner /> Accepting... </> :

                                'Accept'

                        }

                    </Button>
                </Row>

            </ModalFooter>

        </Modal>
            <FloatingLogo />
        </>
    )

}

export default AcceptInvitation;
