import React, { useRef, useState } from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Form, FormGroup } from 'reactstrap';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import { AmplifyAuthError } from '../../../services/auth';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { useAppDispatch } from '../../../redux/hooks';
import { signInUser } from '../../../redux/slices/auth-slice/auth-slice';
import './EmailLogin.css';

type formData = {
    username: string,
    password: string
}

const EmailLogin: React.FunctionComponent<{}> = () => {
    const [invalidLogin] = useState(false);
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({show: false});
    const [loginAlertState, setLoginAlertState] = useState<AlertState>({show: false});
    const redirectToConfirm = useRef<HTMLAnchorElement>(null);
    const [username, setUsername] = useState<string>('');
    const dispatch = useAppDispatch();
    
    
    const {register, handleSubmit, errors} = useForm<formData>({criteriaMode: 'all'});
    
    const onSubmit = async (data: formData) => {
        setUsername(data.username);
        try {
            setLoginAlertState({ show: true, message: "Logging in..." });
            console.log("Email Login");
            
            const signInResult = await dispatch(signInUser({username: data.username, password: data.password}));
            if(signInUser.fulfilled.match(signInResult)){
                setLoginAlertState({ show: false });
            }else{
                setLoginAlertState({ show: false });
                
                setErrorAlertState({show: true, message: signInResult.error.message});
            }
        } catch (error) {
            //const { message, code } = error;
            setLoginAlertState({ show: false });
            return handleRedirect(error, data.username);

            //TODO
            //setErrorAlertState({show: true, message: message || "Failed to login. Please try again."});
            //return handleRedirect(code);
        }
    };

    const handleRedirect = (error: any, username: string) => {
        const {message, code} = error;
        switch (code) {
            case AmplifyAuthError.UserNotConfirmedException.code:
                redirectToConfirm?.current?.click();
                break;
            default:
                setErrorAlertState({show: true, message: message || "Failed to login. Please try again."});
                break;
        }
    }

    const handleError = (elementName: any) => {
        return <ErrorMessage
            errors={errors}
            name={elementName}
            render={({ messages }) => {
            console.log("messages", messages);
            return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>{message}</p>
                ))
                : null;
            }}
        />
    }

    return (
        <div id="email__login__container">
            <Form id="email__login__form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <FormLabel>Username</FormLabel>
                    <FormControl className="form-input" data-cy="username" type="username" name="username" ref={register({required: {value: true, message: 'Username is required'}, maxLength: {value: 50, message:'Maximum 50 characters'}})}/>
                    {handleError("username")}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Password</FormLabel>
                    <FormControl className="form-input" data-cy="password" type="password" name="password" ref={register({required: {value: true, message: 'Password is required'}, maxLength: {value: 50, message: 'Password max lenght is 50'}, minLength: {value: 8, message: 'Minimum of 8 characters required'}})} />
                    {handleError("password")}
                </FormGroup>
                
                <FormGroup>
                    <Link id="forgot-password" to="/auth/reset-password">Forgot password?</Link>
                </FormGroup>
                <FormGroup>
                    <input type="submit" data-cy="login" className="btn btn-lg btn-login" value="Log In"></input>
                </FormGroup>
            </Form>
            {
                invalidLogin ? <div className='error'> Invalid login </div>: null
            } 

            <AlertModal title="Login" type={AlertType.Processing} state={loginAlertState} />
            <AlertModal type={AlertType.Error} state={errorAlertState} /> 
            <Link to={`/auth/confirm-account/${username}`} ref={redirectToConfirm} hidden/>
        </div>
    );
}

export default EmailLogin;
