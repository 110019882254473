import { lazy, Suspense, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { Redirect, Route, Router , Switch } from 'react-router-dom';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { TableProvider } from './contexts/TableContext';
import {Amplify} from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import awsconfig from './aws-exports';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { useOnlineStatus } from './utilities/useOnlineStatus/useOnlineStatus';
import { loadUser, selectAuthState } from './redux/slices/auth-slice/auth-slice';
import { SignalWifiOffOutlined } from '@material-ui/icons';
import InlineAlert from './helpers/InlineAlert/InlineAlert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Invitation from './pages/Invitation';
import {createBrowserHistory} from 'history';
import PolicyStatement from './pages/privacy/PolicyStatement';

const browserHistory = createBrowserHistory();
Amplify.configure(awsconfig);
toast.configure();

const AdminLayout = lazy (() => import("./layouts/Admin"));
const AuthLayout = lazy(() => import("./layouts/Auth"));
const SignInLayout = lazy(() => import('./layouts/SignIn'));

///*
const httpApiUrl = process.env.REACT_APP_REST_API_URL;
const gqlApiUrl = process.env.REACT_APP_GQL_API_URL;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const cognitoIdPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
const userPoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const region = process.env.REACT_APP_REGION;
const oauthDomain = `tap-sign-in-dev.auth.${region}.amazoncognito.com`;
const envProps = {
  httpApiUrl,
  gqlApiUrl,
  userPoolClientId,
  userPoolId,
  cognitoIdPoolId,
  region,
  oauthDomain,
}
///*

function App() {

  const authState = useAppSelector(selectAuthState);
  const dispatch = useAppDispatch();
  const isOnline = useOnlineStatus();

  console.info('ENV_PROPS:',envProps);

  useEffect(() => {
    if(authState.loadUserStatus === 'idle') {
      dispatch(loadUser());
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log("in onAuthUIStateChange");

      console.log("nextAuthState ", nextAuthState);
      console.log("nextAuthState ", authData);

      dispatch(loadUser());
    });
  }, [authState, dispatch, isOnline]);

  if(authState.loadUserStatus === 'loading'){
    return <div style={{height:"100vh", width:"100vw", display:'flex', justifyContent:'center', alignItems:'center'}}>
    <Spinner></Spinner> Please wait, Tap is brewing...
  </div>;
  }


  return (
    <Router history={browserHistory}>
      <Suspense fallback={
        <div style={{height:"100vh", width:"100vw", display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Spinner></Spinner>&nbsp; <span style={{fontStyle: 'italic'}}>Page loading...</span>
        </div>
      }>
      <>{!isOnline ? <InlineAlert message='You are not connected to the internet. Some actions may not work until you are back online.' icon={<SignalWifiOffOutlined/>} />: ''}</>
      <Switch>
        <AuthenticationProvider>
          <TableProvider>
            {
              authState.user && <>
              <Route path="/" exact={true}  render={props => <AdminLayout />} />
              <Route path="/admin"  render={props => <AdminLayout />} />
              </>
            }

            {
              !authState.user && <>
              <Route path="/" exact={true} render={props => <AuthLayout />} />
              <Route path="/admin" render={props => <AuthLayout />} />
              </>
            }
            
            <Route path="/invitation" render={props => <Invitation/>} />
            <Route path="/auth" render={props => <AuthLayout />} />
            <Route path="/signin" render={props => <SignInLayout />} />
            {/* <Route path="/policy-statement" render={props => <PolicyStatement />} /> */}

          </TableProvider>
        </AuthenticationProvider>
      </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
