import { Card, CardBody, CardTitle } from 'reactstrap'
import EmailLogin from '../EmailLogin/EmailLogin';
import OAuthLogin from '../OAuthLogin/OAuthLogin';
import FloatingLogo from '../../FloatingLogo/FloatingLogo';
import './AuthFlowWrapper.css';
import { useState } from 'react';
import CompanySignUpFlow from '../SignUpFlow/SignUpFlow';
import IndividualSignUpFlow from '../IndividualSignUpFlow/IndividualSignUpFlow';


function AuthFlowWrapper() {

    const ACTIVE_TAB = 'ActiveTab';
    const tabs = {
        Company: 'Company',
        Person: 'Individual'
    }


    const [isSigningUp, setIsSigningUp] = useState<boolean>(false);
    const activeTab = () => localStorage.getItem(ACTIVE_TAB) || tabs.Company;

    const toggleActiveTab = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const menuTabs = document.getElementsByClassName('menu__tab');
        [].forEach.call(menuTabs, function (tab: Element) { tab.classList.remove('active') });
        event.currentTarget.classList.add('active');

        const bodyContents = document.getElementsByClassName('tab__body__content');
        [].forEach.call(bodyContents, function (body: Element) { body.setAttribute('hidden', 'true') });

        const activeTabTitle = event.currentTarget.getAttribute('title');
        localStorage.setItem(ACTIVE_TAB, activeTabTitle || tabs.Company);
        [].forEach.call(bodyContents, function (body: Element) {
            if (body.getAttribute('title') === activeTabTitle) {
                body.removeAttribute('hidden');
            }
        });
    }

    const signUpButton = () => {
        return <div className="link-btn-container" onClick={() => setIsSigningUp(true)} style={{ backgroundColor: '#20BDCB' }} >
            Sign Up
        </div>
    }

    if (isSigningUp) {
        if (activeTab() === tabs.Company) {
            return <CompanySignUpFlow onComplete={() => setIsSigningUp(false)} />
        }

        return <IndividualSignUpFlow onComplete={() => setIsSigningUp(false)} />;
    }


    return (

        <div id="auth__flow__wrapper__container">
            <Card>
                <CardTitle>
                    <div id="menu__tabs__wrapper">
                        <div data-cy="company-sign-up" className={`menu__tab ${activeTab() === tabs.Company ? 'active' : ''}`} title={tabs.Company} onClick={toggleActiveTab}>
                            <span>Company</span>
                        </div>
                        <div data-cy="individual-sign-up" className={`menu__tab ${activeTab() === tabs.Person ? 'active' : ''}`} title={tabs.Person} onClick={toggleActiveTab}>
                            <span>Individual</span>
                        </div>
                    </div>
                </CardTitle>
                <CardBody>
                    <div className="tab__body__content" data-cy="company-sign-up" title={tabs.Company} hidden={activeTab() === tabs.Company ? false : true} >
                        <p id="welcome">Get started</p>
                        <p id="log-in-prompt">Log into your account</p>
                        <EmailLogin />

                        <div id="signup-title" style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                            <h6 style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>Don't have an account?</h6>
                        </div>
                        <div data-cy="sign-up" style={{ cursor: 'pointer' }}>
                            {signUpButton()}
                        </div>
                    </div>
                    <div className="tab__body__content" data-cy="individual-sign-up" title={tabs.Person} hidden={activeTab() === tabs.Person ? false : true}>
                        <p id="welcome">Get started</p>
                        <p id="log-in-prompt">Log into your account</p>
                        <EmailLogin />
                        <div id="oauth-title" style={{ textAlign: "center", marginBottom: "1rem" }}>
                            <h6 style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>Or Log In with</h6>
                        </div>
                        <OAuthLogin />

                        <div id="signup-title" style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                            <h6 style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}>Don't have an account?</h6>
                        </div>
                        <div data-cy="sign-up" style={{ cursor: 'pointer' }}>
                            {signUpButton()}
                        </div>
                        <span style={{ marginTop: 10, fontSize: '0.7em' }}>Powered by Codeimple</span>
                    </div>
                </CardBody>
            </Card>
            <FloatingLogo />
        </div>
    );
}

export default AuthFlowWrapper
