//import Mixpanel from 'mixpanel';
import mixpanel from 'mixpanel-browser';

mixpanel.init('741c3c3f20202f12809f3b1d8246dcff');
//*var mixpanel = Mixpanel.init('741c3c3f20202f12809f3b1d8246dcff');


export const setUser =async (param: {event: string, props: any}) =>{
    const {event, props} = param;
    // create or update a user in Mixpanel Engage
    // mixpanel.people.set('billybob', {
    //     $first_name: 'Billy',
    //     $last_name: 'Bob',
    //     $created: (new Date('jan 1 2013')).toISOString(),
    //     plan: 'premium',
    //     games_played: 1,
    //     points: 0
    // });
    mixpanel.people.set(event, props);
}

export const track = async (param: {event: string, props: any}) => {
    const {event, props} = param;
    mixpanel.track(event, props);
}

export enum MixpanelEvent{
    SIGN_UP = 'Signed Up',
    SIGN_IN = 'Signed In',
    PLAN_SUBSCRIPTION = 'Plan Subscription',
    SCREEN_EVENT = 'Screen Event',
};

export enum MixpanelPropName{
    SCREEN_ACTIVITY = 'Screen Activity',
    SCREEN_PATH = 'Screen Path',
};
/*
///* Event Names
static const String signIn = 'Signed In';
static const String signOut = 'Signed Out';
static const String signUp = 'Signed Up';
static const String permGranted = 'Permission Granted';
static const String inviteLinkClicked = 'Invitation Link Clicked';
static const String sosEngaged = 'SOS Feature Engaged';
static const String evacEngaged = 'Evacuation Feature Engaged';
static const String appCrash = 'App Crash';
static const String invitePreRegistered = 'Invite Pre-Registered';
static const String qrCodeInviteSignIn = 'QR Code Invite Sign In';
static const String appLoadTime = 'App Load Time';
static const String screenOnTime = 'Session Length';
static const String appResumed = 'App Resumed'; //after inactivity
static const String screenEvent = 'Screen Event';
static const String addedHousemate = 'Housemate Added';
static const String companyInviteDeclined = 'Company Invite Declined';

///* Prop Names
static const String screenActivity = 'Screen Activity';
static const String screenName = 'Screen Name';
static const String activeDashboardTab = 'Active Dashboard Tab';
*/