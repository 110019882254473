import { GraphQLResult } from '@aws-amplify/api';
import { RequestEvent } from './request-event';
export class ResponseEvent {
    responseData: GraphQLResult<any>;
    dateTime: Date;
    event: RequestEvent;
    requestState: "in-queue" | "processing" | "failed" | "success" | "new";
    error: any | undefined

    constructor(event: RequestEvent, data: object, error?: any | undefined ) {
        this.responseData = data;
        this.dateTime = new Date();
        this.requestState = "new";
        this.event = event;
        this.error = error;
    }
}
