import { faSignOutAlt, faBell, faIdBadge, faTimes } from "@fortawesome/free-solid-svg-icons";
import { get, set } from "local-storage";
import { createContext, PropsWithChildren, useState } from "react";
import Site from "../models/site";
import Status from "../models/status";
import TableColumn from "../models/table-column";
import TapRow from "../models/tap-row";
import RowOption from "../models/row-option";
import { v4 as uuidv4 } from 'uuid';
import { LocalStorageService as lss } from "../services/local-storage-service";
import { TapTables }  from 'tap-types/lib/AccountService';
import { BLANK_USER_ID } from "../constants";

interface TableContextProps {
    getColumns: (tableId: string) => Promise<TableColumn[]>,
    columns: Map<string, TableColumn[]>,
    getRows: (tableId: string) => Promise<TapRow[]>,
    rows: Map<string, TapRow[]>,
    getUserOptions: (tableId: string) => Promise<RowOption[]>,
    rowOptions: Map<string, RowOption[]>,
    searchTexts: Map<string, string>,
    site: string,
    dates: Map<string, Date>,
    filters: Map<string, string>,
    getVisitorTypes: (tableId: string) => Promise<string[]>,
    visitorTypes: Map<string, string[]>,
    getStatuses: (tableId: string) => Promise<Status[]>,
    statuses: Map<string, Status[]>,
    deleteRow: (row: TapRow, tableId: string) => Promise<void>,
    addRow: (row: TapRow, tableId: string) => Promise<void>,
    addColumn: (column: TableColumn, tableId: string) => Promise<void>,
    addStatus: (color: string, title: string, tableId: string) => Promise<void>,
    updateStatuses: (changedStatuses: Status[], tableId: string) => Promise<void>,
    updateSearchText: (searchText: string, tableId: string) => Promise<void>,
    filterVisitorType: (filter: string, tableId: string) => Promise<void>,
    filterByDate: (date: Date, tableId: string) => Promise<void>,
    filterBySite: (site: Site) => Promise<void>,
    searchPeople: (searchText: string, tableId: string) => Promise<TapRow[]>,
    updateColumnName: (columnId: string, newName: string, tableId: string) => Promise<void>,
    updateRow: (row: TapRow, tableId: string) => Promise<void>,
    refreshTables: () => Promise<void>,
    addSubscribedSite: (tableId: string, sites: string ) => Promise<void>,
    unSubscribeSite: (tableId: string, sites: string ) => Promise<void>,
    getSubscribedSites: (tableId: string) => Promise<string[]>,
    addSubscribedRole: (tableId: string, role: string ) => Promise<void>,
    unSubscribeRole: (tableId: string, role: string ) => Promise<void>,
    getSubscribedRoles: (tableId: string) => Promise<string[]>,
}

const ROWS: string = 'rows';
export const COLUMNS: string = 'columns';
export const STATUS: string = 'status';
const SUBSCRIBED_SITES: string = "subscribed_sites";
const SUBSCRIBED_ROLES: string = "subscribed_roles";




const rowsRepository: Map<string, TapRow[]> = new Map<string, TapRow[]>([
  
]);

const columnsRepository: Map<string, TableColumn[]> = new Map<string, TableColumn[]>([
    
]);

export const defaultStatuses = [{
    color: '#00c875',
    title: 'Done',
    id: 'done'
},
{
    color: '#bb3354',
    title: 'Stuck',
    id: 'stuck'
},
{
    color: '#5559df',
    title: 'Working on it',
    id: 'workingonit'
}];

const statusRespository: Map<string, Status[]> = new Map<string, Status[]>([
]);

const getTypes = () => {
    const visitorTypes = new Map<string, string[]>();

    return visitorTypes;
}

const retrieveRows = () => {
    const rows = new Map<string, TapRow[]>();
    const rowsObject = get<Object>(ROWS) as any;

    if (rowsObject) {
        return rows;
    }else {
        return rowsRepository;
    }
    
}

export const retrieveColumns = () => {
    const columns = new Map<string, TableColumn[]>();

    return columns;
}

export const retrieveStatuses = () => {
    const statuses = new Map<string, Status[]>();
    const statusesObject = get<Object>(STATUS) as any;

    if (statusesObject) {
        
    } else {
        return statusRespository;
    }

    return statuses;
}

//SHARING*****************
const retrieveSubscribedSites = () => {
    const subedSites = new Map<string, string[]>();
    const sitesObj = lss.getData<Object>(SUBSCRIBED_SITES) as any;

    if (sitesObj) {
        const tableKeys = Object.keys(sitesObj);
        for (let keyIndex = 0; keyIndex < tableKeys.length; keyIndex++) {
            const key = tableKeys[keyIndex];
            const sitesArray = sitesObj[key] as string[];
            subedSites.set(key, sitesArray);
        }
        return subedSites;
    }

    return subedSites;
}

const retrieveSubscribedRoles = () => {
    const subedRoles = new Map<string, string[]>();
    const rolesObj = lss.getData<Object>(SUBSCRIBED_ROLES) as any;

    if (rolesObj) {
        const tableKeys = Object.keys(rolesObj);
        for (let keyIndex = 0; keyIndex < tableKeys.length; keyIndex++) {
            const key = tableKeys[keyIndex];
            const rolesArray = rolesObj[key] as string[];
            subedRoles.set(key, rolesArray);
        }
        return subedRoles;
    }

    return subedRoles;
}
//********************** */

export const TableContext = createContext<Partial<TableContextProps>>({});

export const TableProvider = ({ children }: PropsWithChildren<{}>) => {

    const [columns, setColumns] = useState<Map<string, TableColumn[]>>(retrieveColumns());

    const [rows, setRows] = useState<Map<string, TapRow[]>>(retrieveRows());

    const [visitorTypes] = useState<Map<string, string[]>>(getTypes());

    const [statuses, setStatuses] = useState<Map<string, Status[]>>(retrieveStatuses());

    const [searchTexts, setSearchText] = useState<Map<string, string>>(new Map<string, string>());

    const [site, setSite] = useState<undefined | Site>();

    const [dates, setDate] = useState<Map<string, Date>>(new Map<string, Date>());

    const [filters, setFilter] = useState<Map<string, string>>(new Map<string, string>());

    const [subscribedSites, setSubcribedSites] = useState<Map<string, string[]>>(retrieveSubscribedSites());
    
    const [subscribedRoles, setSubcribedRoles] = useState<Map<string, string[]>>(retrieveSubscribedRoles());

    const refreshTables = async () => {
        setColumns(new Map(Object.entries(Object.fromEntries(retrieveColumns()))));
        setStatuses(new Map(Object.entries(Object.fromEntries(retrieveStatuses()))));
    }

    //SHARING SITES
    const addSubscribedSite = async (tableId: string, site: string) => {
        const sites = subscribedSites?.get(tableId) ?? [];
        const exists = sites.find(e => e === site);
        if(exists) return;
        updateSubscribedSites(tableId, [...sites, site]);
    }

    const updateSubscribedSites = async(tableId: string, sites: string[]) => {
        setSubcribedSites(previousSites => {
            previousSites.set(tableId, sites);
            const rowsObject = Object.fromEntries(previousSites);
            const tablesKey = Object.keys(rowsObject);
            const obj = {} as any;

            for (let index = 0; index < tablesKey.length; index++) {
                const key = tablesKey[index];
                obj[key] = rowsObject[key];
            }

            lss.saveData(SUBSCRIBED_SITES, obj);
            return new Map(Object.entries(Object.fromEntries(previousSites)));
        });
    }

    const unSubscribeSite = async(tableId: string, site: string) => {
        const siteSubs = subscribedSites.get(tableId);
        if(siteSubs) {
            const siteToDelete = siteSubs.find(e => e === site);
            if(siteToDelete) siteSubs.splice(siteSubs.indexOf(siteToDelete, 0), 1);
            updateSubscribedSites(tableId, siteSubs);
        }
    }
    
    const getSubscribedSites = async(tableId: string) => {
        let sites = subscribedSites?.get(tableId) || [];
        return sites;
    }

    //SHARING ROLES

    const addSubscribedRole = async (tableId: string, role: string) => {
        const roles = subscribedRoles?.get(tableId) ?? [];
        const exists = roles.find(e => e === role);
        if(exists) return;
        updateSubscribedRoles(tableId, [...roles, role]);
    }

    const updateSubscribedRoles = async(tableId: string, roles: string[]) => {

        setSubcribedRoles(previousRoles => {
            previousRoles.set(tableId, roles);
            const rowsObject = Object.fromEntries(previousRoles);
            const tablesKey = Object.keys(rowsObject);
            const obj = {} as any;

            for (let index = 0; index < tablesKey.length; index++) {
                const key = tablesKey[index];
                obj[key] = rowsObject[key];
            }

            lss.saveData(SUBSCRIBED_ROLES, obj);
            return new Map(Object.entries(Object.fromEntries(previousRoles)));
        });
    }

    const unSubscribeRole = async(tableId: string, site: string) => {
        const roleSubs = subscribedRoles.get(tableId);
        if(roleSubs) {
            const roleToDelete = roleSubs.find(e => e === site);
            if(roleToDelete) roleSubs.splice(roleSubs.indexOf(roleToDelete, 0), 1);
            updateSubscribedRoles(tableId, roleSubs);
        }
    }
    
    const getSubscribedRoles = async(tableId: string) => {
        let roles = subscribedRoles?.get(tableId) || [];
        return roles;
    }

    //ROWS

    const addRow = async (row: TapRow, tableId: string) => {
        const tableRows = rows.get(tableId) ?? [];
        updateRows([...tableRows, row], tableId);
    }

    const deleteRow = async (row: TapRow, tableId: string) => {
    }

    const updateRow = async (tapRow: TapRow, tableId: string) => {
        console.log(tapRow);
        const tableRows = rows.get(tableId) ?? [];

        if (tapRow.id === BLANK_USER_ID) {
            tapRow.id = uuidv4();

            await updateRows([...tableRows, tapRow], tableId);
            return;
        }

        const rowIndex = tableRows.findIndex(row => row.id === tapRow.id);
        console.log(rowIndex);
        if (rowIndex !== -1) {
            tableRows.splice(rowIndex, 1, tapRow);
            updateRows([...tableRows], tableId);
            return;
        }


    }

    const updateRows = (updatedRows: TapRow[], tableId: string) => {
        setRows(previousRows => {
            previousRows.set(tableId, updatedRows);
            return new Map(Object.entries(Object.fromEntries(previousRows)));
        });
    }

    //COLUMNS

    const addColumn = async (column: TableColumn, tableId: string) => {
        const tableColumns = columns.get(tableId) ?? [];
        tableColumns.push(column);
        updateColumns(tableColumns, tableId);
    }

    const updateColumnName = async (columnId: string, newName: string, tableId: string) => {
        const tableColumns = columns.get(tableId) ?? [];
        const column = tableColumns.find(column => column.id === columnId);

        if (column) {
            column.name = newName;
            updateColumns(tableColumns, tableId);
        }
    }


    const updateColumns = (columns: TableColumn[], tableId: string) => {
        setColumns(previousColumns => {
            previousColumns.set(tableId, [...columns]);
            set(COLUMNS, Object.fromEntries(previousColumns));
            return new Map(Object.entries(Object.fromEntries(previousColumns)));
        });
    }

    //STATUS

    const updateStatuses = async (changedStatuses: Status[], tableId: string) => {
        const tableStatuses = statuses.get(tableId) ?? [];

        for (let index = 0; index < changedStatuses.length; index++) {
            const editedStatus = changedStatuses[index];
            const status = tableStatuses.find(status => status.id === editedStatus.id);

            if (!status) {
                addStatus(editedStatus.color, editedStatus.title, tableId);
                continue;
            }

            status.color = editedStatus.color;
            status.title = editedStatus.title;
        }

        updateStatus(tableStatuses, tableId);
    }

    const addStatus = async (color: string, title: string, tableId: string) => {
        
        const tableStatuses = statuses.get(tableId) ?? [];

        updateStatus(tableStatuses, tableId);
    }

    const updateStatus = (statuses: Status[], tableId: string) => {
        setStatuses(previousStatuses => {
            previousStatuses.set(tableId, statuses);
            set(STATUS, Object.fromEntries(previousStatuses));
            return new Map(Object.entries(Object.fromEntries(previousStatuses)));
        });
    }


    //PEOPLE

    const searchPeople = (searchText: string, tableId: string) => {
        return new Promise<TapRow[]>(resolve => {
            setTimeout(() => {
                const tableRows = rows.get(tableId) ?? [];
                // const filteredResult = tableRows.filter(row => row.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
                resolve(tableRows);
            }, 1000);

        });
    }


    const updateSearchText = async (searchText: string, tableId: string) => {
        searchTexts.set(tableId, searchText);
        setSearchText(new Map(Object.entries(Object.fromEntries(searchTexts))));

        const filter = filters.get(tableId) ?? '';
        const date = dates.get(tableId) ?? new Date();

        await getUpdatedRows(site, searchText, date, filter, tableId);
    }

    const filterVisitorType = async (filter: string, tableId: string) => {
        filters.set(tableId, filter);
        setFilter(new Map(Object.entries(Object.fromEntries(filters))));
        const searchText = searchTexts.get(tableId) ?? '';
        const date = dates.get(tableId) ?? new Date();

        await getUpdatedRows(site, searchText, date, filter, tableId);
    }

    const filterByDate = async (date: Date, tableId: string) => {
        dates.set(tableId, date);
        setDate(new Map(Object.entries(Object.fromEntries(dates))));
        const filter = filters.get(tableId) ?? '';
        const searchText = searchTexts.get(tableId) ?? '';

        await getUpdatedRows(site, searchText, date, filter, tableId);
    }

    const filterBySite = async (site: Site) => {
        setSite(site)
    }


    const getUpdatedRows = async (site: Site | undefined, searchText: string, date: Date, typeFilter: string, tableId: string) => {

       
    }

    const getRows = async (tableId: string) => {
        return rows.get(tableId) ?? [];
    }

    const getColumns = async (tableId: string) => {
        retrieveColumns();
        return columns.get(tableId) ?? columnsRepository.get(tableId) ?? [];
    }


    const getVisitorTypes = async (tableId: string) => {
        return visitorTypes.get(tableId) ?? ['All Visiotrs'];
    }

    const getStatuses = async (tableId: string) => {
        return statuses.get(tableId) ?? [];
    }

    //ROW OPTIONS

    const rowOptionsRepository: Map<string, RowOption[]> = new Map<string, RowOption[]>([
        [`${TapTables.visitors}`, [
            {
                id: 'signOut',
                display: 'Sign Out',
                action: (row: TapRow) => {
                    updateRow(row, TapTables.visitors);
                },
                icon: faSignOutAlt
            },
            {
                id: 'notify',
                display: 'Notify',
                action: (row: TapRow) => { alert(`Notified ${row}`) },
                icon: faBell
            },
            {
                id: 'printBadge',
                display: 'Print Badge',
                action: (row: TapRow) => { alert(`Printed badge for ${row}`) },
                icon: faIdBadge
            }]],
        [`${TapTables.invite}`, [
            {
                id: 'signIn',
                display: 'Sign In',
                action: (row: TapRow) => {
                    deleteRow(row, TapTables.invite);
                    addRow(row, TapTables.visitors);
                    alert(`Signed out ${row}`) 
                },
                icon: faSignOutAlt
            },
            {
                id: 'notify',
                display: 'Notify',
                action: (row: TapRow) => { alert(`Notified ${row}`) },
                icon: faBell
            },
            {
                id: 'printBadge',
                display: 'Print Badge',
                action: (row: TapRow) => { alert(`Printed badge for ${row}`) },
                icon: faIdBadge
            }
        ]],
        [`${TapTables.app_users}`, [
            {
                id: 'delete',
                display: 'Delete',
                action: (row: TapRow) => { 
                    deleteRow(row, TapTables.app_users);
                 },
                icon: faTimes
            }]
    
        ]
    ]);

    
    const getUserOptions = async (tableId: string) => {
        return rowOptions.get(tableId) ?? [];
    }

    

    const [rowOptions] = useState<Map<string, RowOption[]>>(rowOptionsRepository);


    return (<TableContext.Provider value={{ columns, rows, rowOptions, searchTexts, dates, visitorTypes, filters, statuses, refreshTables, addSubscribedRole, unSubscribeRole, getSubscribedRoles, getColumns, deleteRow, getRows, getUserOptions, getVisitorTypes, getStatuses, searchPeople, addRow, addStatus, updateStatuses, filterVisitorType, updateRow, updateColumnName, filterBySite, filterByDate, addColumn, updateSearchText, addSubscribedSite, getSubscribedSites, unSubscribeSite }}>{children} </TableContext.Provider>);
};


