import React from 'react'
import backImg from "../../assets/images/logo-type-back-btn.png";

const BackButton: React.FC<{onClick?: (e?: { preventDefault: () => void; }) => void, imageUrl?: string}> = (props) => {
    //!const backImg = require("../../assets/images/logo-type-back-btn.png").default;
    const clickAction =()=> {
        if(props.onClick){
            props.onClick();
            return;
        }
        window.history.back();
    }
    return (
        <img 
            alt="Back"
            src={props?.imageUrl || backImg} 
            style={{verticalAlign:"text-bottom", cursor:"pointer", marginRight:"0.5rem", width:"25px", borderRadius:"100%"}} 
            onClick={(evt) => clickAction()}
        />
    );
}

export default BackButton
