import React, { useState, useEffect, useContext } from "react";
import requestEventQueueManager from "../../services/sync/event/request/request-event-queue-manager";
import { checkOnlineStatus } from "./checkOnlineStatus";

const onlinePollingInterval = 10000;
const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider: React.FC = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(previousStatus => {

      if(previousStatus === false && online){
        requestEventQueueManager.processQueuedEvents();
      }

      return online;
    });
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export default useOnlineStatus;