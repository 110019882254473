import { createAsyncThunk, createSlice, Draft, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { Tap } from "tap-types";
import { TapLists } from "tap-types/lib/AccountService";
import { AccountRecord } from "tap-types/lib/AccountService/account_record";
import { RecordType } from "tap-types/lib/AccountService/record_type";
import TapList from "../../../interfaces/tap-list";
import { EventType } from "../../../models/event-type";
import TapListItem from "../../../models/list";
import { RequestEvent } from "../../../models/request-event";
import requestEventQueueManager from "../../../services/sync/event/request/request-event-queue-manager";
import { RootState } from "../../store";

export interface ListState {
    lists: TapList[],
    error: string | undefined;
    status: 'idle' | 'loading' | 'failed' | 'success';
}

const initialListState: ListState = {
    lists: [
    ],
    error: undefined,
    status: 'idle'
};

//     deleteList: (listId: string) => Promise<boolean>,
//     editListName: (listId: string, newName: string) => Promise<boolean>,
//     saveListValues: (listId: string, values: TapListItem[]) => Promise<boolean>

export interface CreateListInput {
    listFields: {
        items: TapListItem[],
        title: string
    }
    accountId: string
}

export const addList = createAsyncThunk(
    'list/createList',
    async (args: CreateListInput) => {
        const list: TapList = {
            id: nanoid(),
            ...args.listFields,
            accountId: args.accountId
        }

        const requestInput = new Tap.AccountService.List.TapListInput(args.accountId, list.id, 'creator', {
            ...list
        });

        const createRequest = new RequestEvent(
            { list: requestInput },
            EventType.CreateList
        );

        const isHandled = await requestEventQueueManager.handleEventRequest(createRequest);

        if (isHandled) {
            return list;
        } else {
            throw Error('Failed to enqueue create list request');
        }
    }

);

export const updateList = createAsyncThunk(
    'list/updateList',
    async (update: TapList, { getState }) => {
        const list = (getState() as RootState).list.lists.find(l => l.accountId === update.accountId && l.id === update.id);


        if (!list) {
            throw Error('Cannot find list to update');
        }

        const createRequest = new RequestEvent(
            { update },
            EventType.UpdateList
        );

        const isHandled = await requestEventQueueManager.handleEventRequest(createRequest);

        if (isHandled) {
            return update;
        } else {
            throw Error('Failed to enqueue update list request');
        }
    }
);


export const listSlice = createSlice({
    name: "list",
    initialState: initialListState,
    reducers: {
        syncLists: (state: Draft<ListState>, action: PayloadAction<AccountRecord[]>) => {
            const guestTypes = action.payload.filter(record => record.Type === RecordType.GUEST_TYPE);

            guestTypes.forEach(guestType => {
                const accountId = guestType.PK.split('#')[1];
                const attributesJson = JSON.parse(guestType.Attributes);
            
                const guestTypeAttributes = new Tap.AccountService.List.GuestTypeAttributes(attributesJson);
            
                const listForUpdate = state.lists.find(list => list.accountId === accountId && list.id === TapLists.guestType);
            
                if (!listForUpdate) {
                  state.lists.push({
                      accountId,
                      id: TapLists.guestType,
                      items: guestTypeAttributes.guestTypes,
                      title: 'Guest Types'
                  });
                  return;
                }
            
            
                listForUpdate.items = guestTypeAttributes.guestTypes.map(type => new TapListItem(type.value, type.value));
            });

        }
    },
    extraReducers: (builder) => {
        builder.addCase(addList.fulfilled, (state, action) => {
            state.lists.push(action.payload);
            state.status = 'success';
        }).addCase(updateList.fulfilled, (state, action) => {

            const list = state.lists.find(l => l.accountId === action.payload.accountId && l.id === action.payload.id);

            if (!list) {
                return //Look into how to handle
            }

            list.items = action.payload.items;
            list.title = action.payload.title;
            state.status = 'success';
        })
    },
});

export const selectLists = (state: RootState) => state.list.lists;
export const selectList = (state: RootState, listId: string, accountId: string) => state.list.lists.find(list => list.id === listId && list.accountId === accountId);
export const {syncLists} = listSlice.actions;
export const selectListState = (state: RootState) => state.list.status;
export default listSlice.reducer;
