import { Card, CardBody, Col, Row } from 'reactstrap'
import { PaymentPlan } from '../../models/PaymentPlan'
import './PaymentPlanCard.css'

const PaymentPlanCard:  React.FC<{paymentPlan: PaymentPlan, isActive: boolean}> = (props) => {
    
    const onPlanSelect = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) =>{
        let elems = document.getElementsByClassName('payment__plan');
        [].forEach.call(elems, function(elem: Element){elem.classList.remove('active__payment__plan')});
        evt.currentTarget.classList.add('active__payment__plan');

        //Unselect all options
        const allRbs = document.querySelectorAll('input[name="payment-plan"]');
        [].forEach.call(allRbs, function(rb: Element){rb.removeAttribute('checked')});

        let rbs = evt.currentTarget.getElementsByTagName('INPUT');
        if( rbs[0].getAttribute('type') === 'radio'){
            rbs[0].setAttribute('checked', 'true');
        }
    }

    

    const planBenefits = (benefits: string[]) => {
        return benefits.map((benefit, key)=>{
            return(
                <li key={key}>{benefit}</li>
            );
        });
    }

    
    const isActive = props.isActive;
    const activeChoice = 'active__payment__plan';
    const className = `payment__plan ${isActive ? activeChoice : ''}`;
    return (
        <div className="payment__plan__container">
            <Card className={className} data-cy="payment-plan" onClick={onPlanSelect}>
                <CardBody>
                    <Row>
                        <Col lg={1} md={1} sm={1} style={{paddingTop: '0.4em'}}>
                            {/* <input type="radio" name="payment-plan" value={this.props.paymentPlan.id}/> */}
                            <label className="radio">
                                <span className="radio__input">
                                    {isActive ? (
                                        <input type="radio" name="payment-plan" data-option-id={props.paymentPlan.id} defaultChecked={true}/>
                                    ): (
                                        <input type="radio" name="payment-plan" data-option-id={props.paymentPlan.id} />
                                    )}
                                    <span className="radio__control"></span>
                                </span>
                                <span className="radio__label"></span>
                            </label>
                        </Col>
                        <Col>
                            <span id="plan-name">{props.paymentPlan.planName}</span>&nbsp;
                            <span id="plan-cost">${props.paymentPlan.cost}</span>&nbsp;
                            <span id="plan">{props.paymentPlan.plan}</span>
                            <ul>
                                {planBenefits(props.paymentPlan.benefits)}
                            </ul>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
    
}

export default PaymentPlanCard
