import { RequestEvent } from "../../../../models/request-event";
import { ResponseEvent } from "../../../../models/response-event";
import { API } from "aws-amplify";

import eventQueue from "../request/request-event-queue";
import responseQueue from "../response/response-event-queue";
import { checkOnlineStatus } from "../../../../utilities/useOnlineStatus/checkOnlineStatus";

interface EventQueueState {
  state: "busy" | "idle";
}

let eventQueueState: EventQueueState = { state: "idle" };

const setQueueState = (state: "busy" | "idle") =>
  (eventQueueState.state = state);

const handleEventRequest = async (request: RequestEvent) => {
  const eventEnqueued: boolean = await eventQueue.enqueue(request);

  const shouldDispatchEvent = eventEnqueued && eventQueueState.state === "idle";

  if (shouldDispatchEvent) {
    dispatchEvent();
  }

  return eventEnqueued;
};

const dispatchEvent = async () => {
  setQueueState("busy");
  try {
    //* more discussion on implementation
    const online = await checkOnlineStatus();
    if (!online) {
      setQueueState("idle");
      return;
    }

    let request = await eventQueue.peek();

    if (!request) {
      setQueueState("idle");
      return;
    }

    request.requestState = "processing";

    const responseData = await API.graphql({
      query: request.graphQLMutation,
      variables: request.variables,
    });

    const eventResponse: ResponseEvent = new ResponseEvent(
      request,
      responseData
    );

    await responseQueue.enqueue(eventResponse);
    await eventQueue.dequeue();
  } catch (error) {
    const request = await eventQueue.dequeue();

    if(request){
      try{
        const response = new ResponseEvent(request, {} , error);
        await responseQueue.enqueue(response);
      }catch(e){

      }
    }
  }
  setQueueState("idle");

  const queueLength = await eventQueue.length();

  if (queueLength !== 0) {
    dispatchEvent();
  }
};




const processQueuedEvents = () => {
  if (eventQueueState.state === "busy") {
    return;
  }

  dispatchEvent();
};

const requestEventQueueManager = {
  handleEventRequest,
  processQueuedEvents,
};

export default requestEventQueueManager;
