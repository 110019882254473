import React, { useEffect, useState } from 'react';
import { Row, Spinner } from 'reactstrap';
import { PaymentPlan } from '../../models/PaymentPlan';
import PaymentPlanCard from '../PaymentPlanCard/PaymentPlanCard';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import './PaymentPlanForm.css'
import { fetchPaymentPlans } from '../../redux/slices/payment-slice/payment-slice';
import { fetchAppPaymentPlans } from '../../redux/slices/payment-slice/payment-api';

interface PaymentPlanFormProp{
    nextStep: (planId: string) => void,
}
interface FormState {
    isformValid: boolean;
}

const PaymentPlanForm: React.FunctionComponent<PaymentPlanFormProp> = (props) => {
    const dispatch = useAppDispatch();
    dispatch(fetchPaymentPlans()); //Should be inside use effect
    const flowData = useAppSelector((state) => state.auth.signUpFlowData);
    const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[]>([]);
    const [state, setState] = useState<FormState>({isformValid: false});

    useEffect(() => {
        fetchPlans().catch(console.error);
    }, []);

    const fetchPlans = async () => {
        setPaymentPlans((await fetchAppPaymentPlans()).data);
    }

    const renderPaymentPlans = (plans: PaymentPlan[]) => {
        return plans.map((plan, key)=>{
            return <Row className="mb-5" key={key}>
                <PaymentPlanCard paymentPlan={plan} isActive={plan.id === flowData?.paymentPlan?.paymentOption} key={key}/>
            </Row>
        });
    }

    const validateContinue = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) =>{
        evt.preventDefault();

        const elems = document.querySelectorAll('input[name="payment-plan"]');
        let hasSelectPlan = [...elems].some((val: Element)=> val.hasAttribute('checked'));

        if(hasSelectPlan){
            const planId = [...elems].find((elem: Element) => elem.getAttribute('checked') === 'true')?.getAttribute('data-option-id') ?? 'none';
            setState({isformValid: true});
            props.nextStep(planId);
        }else{
            setState({isformValid: false});
        }
    }

    return (
        <div id="payment__plans__container" style={{}}>
            {renderPaymentPlans(paymentPlans) ?? <div style={{textAlign: 'center'}}><Spinner></Spinner> Plans loading, please wait...</div>}
            <div>
                {state.isformValid === false ? (<span className="error">Please select a payment plan</span>) : null}
                <input onClick={validateContinue} data-cy="next-step" type="submit" className="btn btn-lg form-btn" value="Pay"></input>
            </div>
        </div>
    );

}

export default PaymentPlanForm
