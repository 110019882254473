import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signIn, signOut, isSignedIn } from '../../../services/auth';
import { RootState } from '../../store';
import { SignUpFlowInterface } from '../../../interfaces/signup-flow-interface';
import { get, set } from 'idb-keyval';
import TapRow from '../../../models/tap-row';
import { fetchLoggedInUser, LOGGED_IN_USERS } from './auth-api';
import { Tap } from 'tap-types';

export interface AuthState {
  user: Tap.UserService.User | undefined;
  tempUserRecord?: TapRow | undefined;
  //cognitoUser: CognitoUser | undefined;
  error: string | undefined;
  loadUserStatus: 'idle' | 'loading' | 'failed' | 'success';
  authStatus: 'authenticating' | 'signed-in' | 'signed-out' | 'auth-error';
  signUpFlowData: SignUpFlowInterface | undefined;
}
export interface UserState {
  user: Tap.UserService.User | undefined;
  tempUserRecord: TapRow | undefined;
  error: string | undefined;
  status: 'idle' | 'loading' | 'failed' | 'success';
  signUpFlowData: SignUpFlowInterface | undefined;
}

async function persistAuthedUser(user: Tap.UserService.User) {
  const users = await get<Tap.UserService.User[]>(LOGGED_IN_USERS) || [];
  users.push(user);
  set(LOGGED_IN_USERS, users);
}

async function removeUnAuthedUser(username: string) {
  const users = (await get(LOGGED_IN_USERS) || []) as Tap.UserService.User[];
  const userIndex = users.findIndex(u => u.username === username);
  if (userIndex < 0) return;
  users.splice(userIndex, 1);
  set(LOGGED_IN_USERS, users);
}


export const initialAuthState: AuthState = {
  user: undefined,
  tempUserRecord: undefined,
  //cognitoUser: undefined,
  error: undefined,
  loadUserStatus: 'idle',
  authStatus: 'signed-out',
  signUpFlowData: undefined,
};

export const signInUser = createAsyncThunk(
  'user/signin',
  async (param: { username: string, password: string }, { rejectWithValue }) => {
    const { username, password } = param;
    console.log('auth slice here');
    try {
      const cognitoUser = await signIn(username, password);
      
      if (!cognitoUser){
        return rejectWithValue('Failed to sign in, please try again.');
      }

      const user = await fetchLoggedInUser();
      if (user){
        return user;
      }
      return rejectWithValue('Failed to sign in, please try again.');
    } catch (error) {
      throw error;
    }
  }
)

export const checkUserSignedIn = createAsyncThunk(
  'user/isSignedIn',
  async () => {
      const signedIn = await isSignedIn();

      return {
        isSignedIn: signedIn
      };
  }
    
);

export const signOutUser = createAsyncThunk(
  'user/signout',
  async () => {
    
    const isSignedOut = await signOut();

    if(!isSignedOut){
      throw Error('Sign out failed');
    }
    
    return undefined;
  }
);

export const loadUser = createAsyncThunk(
  'user/load',
  async () => {
    try {
      const userSignedIn = await isSignedIn();

      if (!userSignedIn) {
        return;
      }

      const result = await fetchLoggedInUser();
      if (result) return result;
      throw new Error('Failed to load current user');
    } catch (error) {
      throw error;
    }
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState: initialAuthState,
  reducers: {
    setUser: (state, action: PayloadAction<Tap.UserService.User>) => {
      state.user = action.payload;
      state.loadUserStatus = "idle";
    },
    saveSignUpFlowData: (state, action: PayloadAction<SignUpFlowInterface>) => {
      state.signUpFlowData = action.payload;
    },
    saveTempUserRecord: (state, action: PayloadAction<TapRow>) => {
      state.tempUserRecord = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.authStatus = 'authenticating';
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.authStatus = 'signed-in';
        state.user = action.payload;
        persistAuthedUser(action.payload);
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.authStatus = 'auth-error';
        state.error = action.error.message;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loadUserStatus = "failed";
        //state.authStatus = 'signed-out';
        state.error = action.error.message;
      })
      .addCase(loadUser.pending, (state) => {
        state.loadUserStatus = "loading";
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.loadUserStatus = "success";
        state.authStatus = 'signed-in';
        state.user = action.payload;
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.authStatus = 'auth-error';
        state.error = action.error.message;
      })
      .addCase(signOutUser.pending, (state) => {
        state.authStatus = 'authenticating';
      })
      .addCase(signOutUser.fulfilled, (state) => {
        removeUnAuthedUser(state.user?.username || '');
        //state.user = undefined;
        state.authStatus = 'signed-out';
      }).addCase(checkUserSignedIn.fulfilled, (state, action) => {
        if(!action.payload.isSignedIn){
          state.authStatus = "signed-out";
          state.user = undefined;
        }
      });
  },
});

export const selectAuthState = (state: RootState) => state.auth;
export const selectUser = (state: RootState) => state.auth.user;
export const selectUserStatus = (state: RootState) => state.auth.loadUserStatus;
export const { setUser, saveSignUpFlowData, saveTempUserRecord } = authSlice.actions;
export default authSlice.reducer;
