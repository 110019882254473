import React, { useState } from 'react'
import ConfirmAUsercountPartial from '../ConfirmAUsercountPartial/ConfirmAUsercountPartial';
import CardFormWrapper from '../CardFormWrapper/CardFormWrapper';
import IndividualSignUpForm from '../IndividualSignUpForm/IndividualSignUpForm';
import { confirmSignUp } from '../../../services/auth';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import FloatingLogo from '../../FloatingLogo/FloatingLogo';
import SignIn from '../../../pages/SignIn';



const IndividualSignUpFlow: React.FunctionComponent<{onComplete? : () => void}> = (props) => {

    const signUpFormData = {
        username:'',
        email: '',
        contactNumber: '',
        firstName: '',
        lastName: '',
        password: '',
        companyName:''
    }

    const userInformation = {
        id: '',
        name: '',
        password: ''
    }


    const [step, setStep] = useState<number>(1);
    const [userInfo, setUserInfo] = useState(userInformation);
    
    const nextStep = () => {
        setUserInfo(userInfo);
        setStep(step + 1);
    };

    const getUserInfo = (id: string, name: string, password: string) =>{
        
        setUserInfo({id: id, name: name, password: password});
        setStep(step+1);
    }

    
    const prevStep = () => {
        setUserInfo(userInfo);
        setStep(step - 1);
    };

    const [resendCodeAlertState,] = useState<AlertState>({show: false});
    const [confirmAccountAlertState, setConfirmAccountAlertState ] = useState<AlertState>({show: false});
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({show: false});

    const handleConfirmCode = async (code: string, username: string) => {
        try {
            setConfirmAccountAlertState({show: true, message: 'Confirming user account...'});
                const result = await confirmSignUp(username, code);
                if(result) {
                    setConfirmAccountAlertState({show: false});
                    nextStep();
                }else {
                    setConfirmAccountAlertState({show: false});
                    setErrorAlertState({show: true, message: 'Something went wrong. Please try again.'});
                }
        } catch (error) {
            setConfirmAccountAlertState({show: false});
            setErrorAlertState({show: true, message: (error as Error).message ?? 'Something went wrong. Please try again.'});
        }  
    }

    const authFlowStep = () => {
        switch (step){
            case 1:
                return( 
                    <CardFormWrapper 
                        title="Individual Sign Up"
                        subTitle="Step 1 of 2, General Information"
                        prevStep={prevStep}
                        childComponent={
                            <IndividualSignUpForm 
                                nextStep={getUserInfo}
                                initialFormData={signUpFormData}
                            />
                        }
                    />
                );
            case 2:
                return(
                    <>
                        <CardFormWrapper 
                            title="Individual Sign Up" 
                            subTitle="Step 2 of 2, Confirm your email"
                            prevStep={prevStep}
                            childComponent={
                                <ConfirmAUsercountPartial 
                                    confirmCode={async (code, username) => await handleConfirmCode(code, username)}
                                    username={userInfo.id}
                                    nextStep={(event?: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                                        event?.preventDefault();
                                        event?.stopPropagation();
                                        setStep(step + 1);
                                        setUserInfo(userInfo);
                                    }}
                                />
                            }
                        />

                        <AlertModal title="Resend PIN" type={AlertType.Processing} state={resendCodeAlertState} />
                        <AlertModal title="Confirm Account" type={AlertType.Processing} state={confirmAccountAlertState} />
                        <AlertModal type={AlertType.Error} state={errorAlertState} /> 
                    </>
                );
    
            default:
                if(props.onComplete){
                    props.onComplete();
                }
                return <SignIn/>
            }
        }

        return (
            <>
                {authFlowStep()}
                <FloatingLogo/>
            </>
        );
    //}
}

export default IndividualSignUpFlow
