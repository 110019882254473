import { RequestEvent } from '../../../../models/request-event';
import { PersistedQueue } from '../../../persisted-queue';
const KEY = 'EVENTQUEUE';
let persistedQueue: PersistedQueue;

const queue = async () => {
    if(!persistedQueue){
        persistedQueue = await PersistedQueue.createAsync(KEY);
    }

    return persistedQueue;
}


const enqueue = async (item: RequestEvent): Promise<boolean> => {
    return (await queue()).enqueue(item);
}

const dequeue = async () => {
    return (await queue()).dequeue() as Promise<RequestEvent | undefined>;
}

const peek = async () => {
    return (await queue()).peek() as Promise<RequestEvent | undefined>;
}

const length = async () => {
    return (await queue()).length();
}

const clear = async () => {
    return (await queue()).clear();
}

const requestEventQueue = {
    enqueue,
    peek,
    dequeue,
    length,
    clear
}

export default requestEventQueue;