import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import BillingForm from '../../BillingForm/BillingForm';
import ConfirmAUsercountPartial from '../ConfirmAUsercountPartial/ConfirmAUsercountPartial';
import PaymentPlanForm from '../../PaymentPlanForm/PaymentPlanForm';
import CardFormWrapper from '../CardFormWrapper/CardFormWrapper';
import CompanySignUpForm from '../CompanySignUpForm/CompanySignUpForm';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { confirmSignUp, signIn } from '../../../services/auth';
import FloatingLogo from '../../FloatingLogo/FloatingLogo';
import { useAppSelector } from '../../../redux/hooks';
import SignIn from '../../../pages/SignIn';
import { SignUpFormInterface } from '../../../interfaces/signup-form-interface';
import { subscribeToPaymentPlan } from '../../../redux/slices/payment-slice/payment-api';
import { fetchLoggedInUser } from '../../../redux/slices/auth-slice/auth-api';


const userInfo = {
    id: '',
    name: '',
    password: ''
}

const CompanySignUpFlow: React.FC<{onComplete? : () => void}> = (props) => {
    const flowData = useAppSelector((state) => state.auth.signUpFlowData);
    const [signUpData, setSignUpData] = useState(flowData);
    const [confirmAccountAlertState, setConfirmAccountAlertState] = useState<AlertState>({ show: false });
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({ show: false });

    const [userInformation, setUserInformation] = useState(userInfo);

    const { flowStep } = useParams<{ flowStep: string }>();

    const getStep = () => {
        const step = flowStep !== undefined ? +flowStep : 1;
        return (step <= 0) ? 1 : step;
    }

    const [step, setStep] = useState<number>(getStep());

    const signUpFormData = {
        username: '',
        email: '',
        contactNumber: '',
        firstName: '',
        lastName: '',
        password: '',
        companyName: ''
    }

    const billingFormData = {
        nameOnCard: '',
        cardNumber: '',
        cardExpirationDate: '',
        cardCVC: '',
        streetAddress: '',
        streetAddressLine2: '',
        city: '',
        stateProvince: '',
        postalZipCode: '',
        country: '',
        acceptTOS: false
    }

    // Proceed to next step
    const nextStep = () => {
        setUserInformation(userInformation);
        setStep(step + 1);
    };

    const getUserInfo = (data: SignUpFormInterface) => {
        setUserInformation({ id: data.username, name: `${data.firstName} ${data.lastName}`, password: data.password });
        setSignUpData({
            signUpFormData: data,
            billingFormData: flowData?.billingFormData,
        });
        setStep(step + 1);
    }

    // Go back to prev step
    const prevStep = () => {
        setUserInformation(userInformation);
        setStep(step - 1);
    }

    const handleConfirmCode = async (code: string, username: string) => {
        try {
            setConfirmAccountAlertState({ show: true, message: 'Confirming user account...' });

            const result = await confirmSignUp(username, code);
            if (result) {
                setConfirmAccountAlertState({ show: false });
        await signIn(signUpData!.signUpFormData!.username, signUpData!.signUpFormData!.password);

                nextStep();
                //setShowLoginModal(true);
            } else {
                setConfirmAccountAlertState({ show: false });
                setErrorAlertState({ show: true, message: 'Something went wrong. Please try again.' });
            }

        } catch (error) {
            const { message } = error as Error;
            setConfirmAccountAlertState({ show: false });
            setErrorAlertState({ show: true, message: message || 'Something went wrong. Please try again.' });
        }

    }

    const redirectToPayNowPro = async (paymentPlanId: string) => {
        const user = await fetchLoggedInUser();

        const redirectUrl = (await subscribeToPaymentPlan({
            planId: paymentPlanId,
            accountId: user!.defaultAccount!.accountId
        })).paymentRedirectUrl;
        window.location.replace(redirectUrl);
    };

const flow = () => {
    switch (step) {
        case 1:
            return (
                <CardFormWrapper
                    title="Company Sign Up"
                    subTitle="Step 1 of 4, General Information"
                    prevStep={() => prevStep()}
                    childComponent={
                        <CompanySignUpForm
                            nextStep={getUserInfo}
                            initialFormData={signUpFormData}
                        />
                    }
                />
            );

        case 2:
            return (
                <>
                    <CardFormWrapper
                        title="Company Sign Up"
                        subTitle="Step 2 of 4, Confirm your email"
                        prevStep={prevStep}
                        childComponent={
                            <ConfirmAUsercountPartial
                                confirmCode={(code, username) => handleConfirmCode(code, username)}
                                username={signUpData?.signUpFormData?.username}
                                nextStep={() => { }}//Handled by afterUserLogin function
                            />
                        }
                    />
                    <AlertModal title="Confirm Account" type={AlertType.Processing} state={confirmAccountAlertState} />
                    <AlertModal type={AlertType.Error} state={errorAlertState} />
                </>
            );

        case 3:
            return (
                <CardFormWrapper
                    title="Company Sign Up"
                    subTitle="Step 3 of 4, Choose your plan"
                    prevStep={prevStep}
                    childComponent={
                        <PaymentPlanForm
                            nextStep={(paymentPlanId: string) => {
                                setSignUpData({
                                    ...signUpData,
                                    paymentPlan: {
                                        paymentOption: paymentPlanId
                                    }
                                });
                                redirectToPayNowPro(paymentPlanId).catch(console.error);
                            }}
                        />
                    }
                />
            );

        default:
            if(props.onComplete){
                props.onComplete();
            }

            return <SignIn />
    }
}

return (
    <>
        {flow()}
        <FloatingLogo />
    </>
);
}

export default CompanySignUpFlow

