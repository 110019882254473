import { set, get, remove } from "local-storage";

// interface LocalStorage{
//     saveData<T>(key: string, value: T): boolean;
//     getData <T>(key: string): T;
//     delete(key: string): void;
// }

export class LocalStorageService{
    static saveData<T>(key: string, value: T): boolean {
        try{
            let result = set<T>(key, value);
            return result;
        }
        catch(Exception){
            let error = Exception as Error;
            console.log(error?.message);
            throw error?.message;
        }
    }

    static getData<T>(key: string): T {
        try{
            let result = get<T>(key);
            return result;
        }
        catch(Exception){
            let error = Exception as Error;
            console.log(error?.message);
            throw error?.message;
        }
    }

    static delete(key: string){
        try{
            remove(key);
        }
        catch(Exception){
            let error = Exception as Error;
            console.log(error?.message);
            throw error?.message;
        }
    }
}

//export default LocalStorageService;