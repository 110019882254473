import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react'
import { FormControl, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form, FormGroup } from 'reactstrap';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { resendConfirmationCode } from '../../../services/auth';
import './ConfirmAUsercountPartial.css'

type formData = {
    confirmationCode: string
}

const ConfirmAUsercountPartial:  React.FunctionComponent<{confirmCode:(code: string, username: string) => void, username: string | undefined, nextStep:() => void}> = (props) => {
    
    const {register, handleSubmit, errors} = useForm<formData>({criteriaMode: 'all'});
    const [resendCodeAlertState, setResendCodeAlertState] = useState<AlertState>({show: false});
    const [resendCodeSuccessAlertState, setResendCodeSuccessAlertState] = useState<AlertState>({show: false});
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({show: false});
    const [username, setUsername] = useState(props.username);

    console.log(props.username);
    console.log(username);

    const onSubmit = (data: formData) => {
        if(!username){
            toast.warning('Please enter a username for confirmation');
            return;
        }

        props.confirmCode(data.confirmationCode, username);
    }

    const handleResendCode = async () => {
        try {
            setResendCodeAlertState({show: true, message: 'Resending PIN...'});
            if(!username){
                toast.warning('Please enter a username for confirmation');
                return;
            }

            const result = await resendConfirmationCode(username);
            if(result){
                setResendCodeAlertState({show: false});
                const {CodeDeliveryDetails: {AttributeName, Destination}} = result;
                const message = <span>{`Code has been sent to your ${AttributeName}`} <strong>{`(${Destination})`}</strong></span>;
                setResendCodeSuccessAlertState({show: true, message: message});
            }

        } catch (error: any) {
            const {message} = error;
            setResendCodeAlertState({show: false});
            setErrorAlertState({show: true, message: message || 'Something went wrong. Please try again.'});
        }
    }

    return (
        <div id="confirm__email__container">
            <Form id="email__login__form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <FormLabel>Confirmation Code</FormLabel>
                    <FormControl data-cy="confirmation-code" className="form-input" type="text" name="confirmationCode" ref={register({required: {value: true, message: 'Confirmation code is required to proceed'}, maxLength: {value: 50, message:'Maximum 50 characters'}})}/>
                    <ErrorMessage
                        errors={errors}
                        name={'confirmationCode'}
                        render={({ messages }) => {
                        console.log("messages", messages);
                        return messages
                            ? Object.entries(messages).map(([type, message]) => (
                                <p className="error" key={type}>{message}</p>
                            ))
                            : null;
                        }}
                    />

                    {
                        username === undefined && <>
                            <FormLabel>Username</FormLabel>
                            <FormControl data-cy="username" value={username} onChange={(event) => setUsername(event.target.value)} type="text" name="username" className="form-input" placeholder="Email or Phone number" ref={register({required: {value: true, message: 'Username is required'}, maxLength: {value: 50, message:'Maximum 50 characters'}})}/>
                        </>
                    }
                </FormGroup>
            
                <p className='instruction__text'>
                    Please check your inbox/sms for a confirmation PIN. <br/>
                    Type the PIN in the box above to confirm your account.
                </p>

                <div id="btn-row">
                    <button type="button" onClick={() => handleResendCode()} className="col form-btn btn-resend" >Resend PIN</button>
                    &nbsp;
                    <input data-cy="confirm" type="submit" className="col form-btn btn-next" value="Next"/>
                </div>
            </Form>
             
            <AlertModal title="Resend PIN" type={AlertType.Processing} state={resendCodeAlertState} />
            <AlertModal title="PIN Resent" type={AlertType.Success} state={resendCodeSuccessAlertState} />
            <AlertModal type={AlertType.Error} state={errorAlertState} /> 
        </div>
    );
}

export default ConfirmAUsercountPartial
