import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faTimes,  } from '@fortawesome/free-solid-svg-icons';
import './InlineAlert.css';
import React from 'react';
 
export const InlineAlert: React.FC<{message: string, icon: React.ReactNode}> =(props) => {
    const closeAlert = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if(e.currentTarget.parentElement){
            e.currentTarget.parentElement.style.display =  'none';
        }
    }
    return (
        <div id='inline__alert__wrapper'>
            <div className="inline__alert">
                <span className="icon__span">{props.icon}</span>
                <span className="message__span">{props.message}</span>
                <span className="close__btn" onClick={closeAlert}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </div>
        </div>
    );
}
export default InlineAlert;
