class TapListItem{
    value: any
    label: string

    constructor(value: any, label: string){
        this.value = value;
        this.label = label
    }
}

export default TapListItem;