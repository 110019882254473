import { createContext, PropsWithChildren, useState } from "react";
import { SignUpFlowInterface } from "../interfaces/signup-flow-interface";
import { LocalStorageService as LSS } from "../services/local-storage-service";
import TapRow from "../models/tap-row";
import { signOut } from "../services/auth";
import { PaymentPlan } from "../models/PaymentPlan";

export interface AuthenticationContextProps {
    paymentPlans: () => Promise<PaymentPlan[]>;
    user: TapRow;
    setCurrentUser: (userId: string, password: string) => Promise<boolean>;
    saveUser: (user: TapRow) => Promise<void>;
    signUpFlowData: SignUpFlowInterface;
    saveSignUpFlowData: (flowData: SignUpFlowInterface) => void;
    logout: () => Promise<void>;
}



export const AuthenticationContext = createContext<Partial<AuthenticationContextProps>>({});
const USERS = "users";
const USER = "currentUser";

const retrieveUsers = () => {
    return [];
};

const retrieveUser = () => {
    return undefined;
};

export const AuthenticationProvider = ({ children }: PropsWithChildren<{}>) => {
    const [user, setUser] = useState<TapRow | undefined>(retrieveUser());
    const [users, setUsers] = useState<TapRow[]>(retrieveUsers());
    const [signUpFlowData, setFlowData] = useState<SignUpFlowInterface>();
    //const [paymentPlans, setPayment]
    //const [confirmUserAccount, setConfirmAccountState] = useState(false);

    const logout = async () => {
        const signout: boolean = await signOut();
        if(!signout) throw Error("Failed to sign out");
        setUser(undefined);
        LSS.delete(USER);
    };

    //If true will redirect to confirm account screen
    const paymentPlans = async () => {
        return staticTempPaymentPlans;
    }

    const setCurrentUser = async (userId: string, password: string) => {
        //TODO recheck password
        const currentUser = users.find((user) => user.id === userId); // && user.rowProperties.get('password') === password);
        setUser(currentUser);

        if (currentUser) {
            const userObject = {
                id: currentUser.id,
                rowProperties:  currentUser.rowProperties,
            };
            LSS.saveData(USER, userObject);
        } else {
            //TODO remove after demo
            return true;
        }
        return typeof currentUser !== "undefined";
    };

    const saveUser = async (user: TapRow) => {
        console.log(users);
        console.log(user);
        setUsers((previousUsers) => {
            previousUsers.push(user);
            const usersObject = previousUsers.map((row) => {
                return {
                    id: row.id,
                    rowProperties: [
                        ...row.rowProperties
                    ]
                };
            });

            LSS.saveData(USERS, usersObject);
            return [...previousUsers, user];
        });
    };

    const saveSignUpFlowData = (flowData: SignUpFlowInterface) => {
        setFlowData(flowData);
    };

    return (
        <AuthenticationContext.Provider
            value={{
                paymentPlans,
                user,
                setCurrentUser,
                logout,
                saveUser,
                signUpFlowData,
                saveSignUpFlowData,
            }}
        >
            {children}{" "}
        </AuthenticationContext.Provider>
    );
};

// TEMP PENDING REFACTOR
const staticTempPaymentPlans : PaymentPlan[] = [
    
];