/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten


export namespace APIMutations {
  export const createVisitor = /* GraphQL */ `
    mutation CreateVisitor (
      $accountId: String!
      $userEmail: String!
      $payload: AccountInput!
      ) {
      createVisitor(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
        Attributes
        Date
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
      }
    }
  `;

export const addOrUpdateLocation = /* GraphQL */ `
  mutation UpsertLocation (
    $accountId: String!
    $userEmail: String!
    $payload: AccountInput!
    ) {
    upsertLocation(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
      Attributes
      PK
      SK
      Type
      _createdBy
      _deleted
      _lastChangedAt
      _version
    }
  }
  `;

export const updateVisitor = /* GraphQL */ `
  mutation UpdateVisitor (
    $accountId: String!
    $userEmail: String!
    $payload: AccountInput!
    ) {
    updateVisitor(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
      Attributes
      Date
      PK
      SK
      Type
      _deleted
      _lastChangedAt
      _version
      _createdBy
    }
  }
  `;

 export const createUser = /* GraphQL */ `
    mutation CreateUser (
      $accountId: String!
      $userEmail: String!
      $payload: AccountInput!
      ) {
      createUser(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        Attributes
        Date
    }
  }
  `;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser (
    $accountId: String!
    $userEmail: String!
    $payload: AccountInput!
    ) {
    updateUser(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
      PK
      SK
      Type
      _deleted
      _lastChangedAt
      _version
      _createdBy
      Attributes
      Date
  }
  }
`;

  export const createInvite = /* GraphQL */ `
    mutation CreateInvite (
      $accountId: String!
      $userEmail: String!
      $payload: InviteInput!
      ) {
      createInvite(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        Attributes
        Date
        IsActive
        OTP
    }
  }
`;

export const updateInvite = /* GraphQL */ `
mutation UpdateInvite (
  $accountId: String!
  $userEmail: String!
  $payload: InviteInput!
  ) {
  updateInvite(accountId: $accountId , payload: $payload , userEmail: $userEmail) {
    PK
    SK
    Type
    _deleted
    _lastChangedAt
    _version
    _createdBy
    Attributes
    Date
    IsActive
    OTP
}
}
`;

export const acceptAccountInvite = /* GraphQL */ `
    mutation AcceptAccountInvite(
      $linkExpiration: AWSTimestamp!
      $email: String!
      $companyName: String!
      $accountId: String!
    ) {
      acceptUserInvite(linkExpiration: $linkExpiration, email: $email, companyName: $companyName, accountId: $accountId) {
        accountId
        companyName
        email
        linkExpiration
      }
}`;

export const acceptInvite = /* GraphQL */ `
    mutation AcceptInvite (
      $accountId: String!
      $OTP: String!
    ) {
      acceptInvite(accountId: $accountId, OTP: $OTP) {
        Attributes
        Date
        IsActive
        OTP
        PK
        SK
        Type
        _createdBy
        _deleted
        _lastChangedAt
        _version
      }
    }
`;

 export const setDefaultAccount = /* GraphQL */ `
    mutation SetDefaultAccount (
      $accountId: String!
      $username: String!
    ) {
      setDefaultAccount(accountId: $accountId, username: $username){
        email
      }
    }
 `;

 export const createRole = /* GraphQL */ `
    mutation CreateRole (
      $accountId: String!
      $userEmail: String!
      $payload: AccountRoleInput!
    ){
      createRole(accountId: $accountId, userEmail: $userEmail, payload: $payload){
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        Attributes
      }
    }
 `;

 export const updateRole = /* GraphQL */ `
    mutation UpdateRole (
      $accountId: String!
      $userEmail: String!
      $payload: AccountRoleInput!
    ){
      updateRole(accountId: $accountId, userEmail: $userEmail, payload: $payload){
        PK
        SK
        Type
        _deleted
        _lastChangedAt
        _version
        _createdBy
        Attributes
      }
    }
 `;

 export const subscribeToPaymentPlan = /* GraphQL */ `
    mutation SubscribeToPaymentPlan (
      $payload: SubscribeToPaymentPlanInput!
    ){
      subscribeToPaymentPlan(payload: $payload){
        paymentRedirectUrl
        paymentPlan{
          SK
          Type
          PlanName
          Cost
          Plan
          Benefits
          _lastChangedAt
          _version
          _deleted
          _createdBy
        }
      }
    }
 `;


}