import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";

export enum AlertType {
    Error,
    Success,
    Info,
    Warn,
    Processing,
    Confirm
};

export type AlertState = {
    show: boolean;
    message?: string | React.ReactNode;
    onConfirm?: Function;
    onCancel?: Function;
}

const AlertModal: React.FunctionComponent<{ type: AlertType, title?: string, state: AlertState }> = (props) => {
    const [showAlert, toggleAlert] = useState<boolean>(false);
    useEffect(() => {
        toggleAlert(props.state.show);
    }, [props.state])

    const confirmAlert = () => {
        return (
            <SweetAlert
                show={showAlert}
                error={false}
                showCancel={true}
                cancelBtnText="Cancel"
                showConfirm={true}
                title={props.title ?? 'Error'}
                onConfirm={() => {
                    if (props.state.onConfirm) {
                        props.state.onConfirm();
                    }

                    toggleAlert(false);
                }}
                onCancel={() => {
                    if (props.state.onCancel) {
                        props.state.onCancel();
                    }

                    toggleAlert(false);
                }}
                closeOnClickOutside={false}
            >
                {props.state.message}
            </SweetAlert>
        );
    }

    const errorAlert = () => {
        return (
            <SweetAlert
                show={showAlert}
                error={true}
                showCancel={true}
                cancelBtnText="Close"
                showConfirm={false}
                title={props.title ?? 'Error'}
                onConfirm={() => { }}
                onCancel={() => toggleAlert(false)}
                closeOnClickOutside={false}
            >
                {props.state.message}
            </SweetAlert>
        );
    }

    const successAlert = () => {

        return (
            <SweetAlert
                show={showAlert}
                success={true}
                showCancel={true}
                cancelBtnText="Close"
                showConfirm={false}
                title={props.title ?? 'Success'}
                onConfirm={() => { }}
                onCancel={() => toggleAlert(false)}
                closeOnClickOutside={false}
            >
                {props.state.message}
            </SweetAlert>
        );
    }

    const warningAlert = () => {

        return (
            <SweetAlert
                show={showAlert}
                warning={true}
                showCancel={true}
                cancelBtnText="Close"
                showConfirm={false}
                title={props.title ?? 'Warning'}
                onConfirm={() => { }}
                onCancel={() => toggleAlert(false)}
                closeOnClickOutside={false}
            >
                {props.state.message}
            </SweetAlert>
        );
    }

    const infoAlert = () => {

        return (
            <SweetAlert
                show={showAlert}
                info={true}
                showCancel={true}
                cancelBtnText="Close"
                showConfirm={false}
                title={props.title ?? 'Notification'}
                onConfirm={() => { }}
                onCancel={() => toggleAlert(false)}
                closeOnClickOutside={false}
            >
                {props.state.message}
            </SweetAlert>
        );
    }

    const processingAlert = () => {

        return (
            <SweetAlert
                show={showAlert}
                cancelBtnText="Close"
                showConfirm={false}
                title={props.title ?? 'Processing'}
                onConfirm={() => { }}
                onCancel={() => toggleAlert(false)}
                closeOnClickOutside={false}
            >
                <Spinner animation="border" /> <br />{props.state.message}
            </SweetAlert>
        );
    }

    const alert = () => {
        switch (props.type) {
            case AlertType.Success:
                return successAlert();
            case AlertType.Error:
                return errorAlert();
            case AlertType.Info:
                return infoAlert();
            case AlertType.Warn:
                return warningAlert();
            case AlertType.Processing:
                return processingAlert();
            case AlertType.Confirm:
                return confirmAlert();
            default:
                return <></>
        }
    }

    return (
        <> {alert()} </>
    );
}

export default AlertModal

