import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { FormControl, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Form, FormGroup } from 'reactstrap';
import { SignUpFormInterface } from '../../../interfaces/signup-form-interface';
import { signUp, SignUpData } from '../../../services/auth';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { useAppSelector } from '../../../redux/hooks';
import { toast } from "react-toastify";
import validator from "validator";
import './CompanySignUpForm.css'


interface SignUpProp{
    initialFormData: SignUpFormInterface,
    nextStep: (data: SignUpFormInterface) =>void,
}

const CompanySignUpForm: React.FunctionComponent<SignUpProp> = (props) =>{
    const flowData = useAppSelector((state) => state.auth.signUpFlowData);
    const { nextStep} = props;
    const [creatingAlertSate, setCreatingAlertSate] = React.useState<AlertState>({show:false, message:""});
    const [errorAlertSate, setErrorAlertState] = React.useState<AlertState>({show: false, message:""});
    
    const {register, handleSubmit, errors} = useForm<SignUpFormInterface>({criteriaMode: "all"});
    const onSubmit = async (data: SignUpFormInterface) => {
        console.log(data);
        const phoneValidationResult = validateContactField(data.contactNumber);
        if(phoneValidationResult){
            data.contactNumber = phoneValidationResult;
        }
        ///**TEMP: fix cognito not sending conf code when phone nos present */
        data.contactNumber = '';
        //** */
        await handleSignUp(data);
    }

    const validateContactField = (value: string) => {
        const val = `${value ?? ""}`.trim();
        if(val.length <=0){return undefined;}//*making phone nullable
        const isValid = validator.isMobilePhone(value);
        if (!isValid) {
            toast.error('Minimum phone contact length is 10 characters.');
            return undefined;
        }
        if (value.length < 10) {
            toast.error('Minimum phone contact length is 10 characters.');
            return undefined;
        }
        if (value.length >= 13) {
            const startDigits = ['1', '2'];
            const isValidDigit = startDigits.some((e)=> e === value.charAt(1));
            //*value.charAt(1) !== '1' || value.charAt(1) !== '2'
            if (!value.startsWith('+') || !isValidDigit) {
                toast.error('Contact number should start with +1');
                return undefined;
            }
        }

        const startDigits = ['1', '2'];
        const isValidDigit = startDigits.some((e)=> e === value.charAt(1));
        //*value.charAt(1) !== '1'
        if (value.startsWith('+') && !isValidDigit) {
            toast.error('Contact number should start with +1');
            return undefined;
        }
        if(value.length === 10){
            return `+1${value}`;
        }
        return value;
    }

    const handleSignUp = async (data: SignUpFormInterface) => {
        try {
            console.log(data);
            setCreatingAlertSate({show: true, message: "Creating account..."});
            const {username, password, firstName, lastName, contactNumber, companyName} = data;
            let signUpData: SignUpData = {
                username: username,
                password: password,
                attributes: {
                    email: username,
                    given_name: firstName,
                    family_name: lastName,
                    phone_number: contactNumber,
                    isCompany: 'true',
                    companyName: companyName
                }
            }

            if(!contactNumber){
                delete signUpData.attributes?.phone_number;
            }
            
            const signUpResult = await signUp(signUpData);
            console.log(signUpResult);
            if(signUpResult){
                setCreatingAlertSate({show:false});
                nextStep(data);
            }else{
                setCreatingAlertSate({show: false});
                setErrorAlertState({show: true, message: "Failed to create account, please try again."});
            }
        } catch (error) {
            setCreatingAlertSate({show: false});
            setErrorAlertState({show: true, message: (error as Error).message?? "Failed to create account. Please try again."});
        }
    }

    const handleError = (elementName: any) => {
        return <ErrorMessage
            errors={errors}
            name={elementName}
            render={({ messages }) => {
            console.log("messages", messages);
            return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>{message}</p>
                ))
                : null;
            }}
        />
    }
    
    return(
        <div id="signup__form__container">
            <Form id="signup__form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <FormLabel>Username <span>*</span></FormLabel>
                    <FormControl data-cy="username" value={flowData?.signUpFormData?.username} type="email" name="username" className="form-input" placeholder="Email" ref={register({required: {value: true, message: 'Email is required'}, maxLength: {value: 50, message:'Maximum 50 characters'}})}/>
                    <ErrorMessage
                        errors={errors}
                        name="username"
                        render={({ messages }) => {
                        console.log("messages", messages);
                        return messages
                            ? Object.entries(messages).map(([type, message]) => (
                                <p className="error" key={type}>{message}</p>
                            ))
                            : null;
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Contact number</FormLabel>
                    <FormControl value={flowData?.signUpFormData?.contactNumber} type="tel" name="contactNumber"  placeholder="+18760011223" className="form-input" ref={register({required:{value: false, message:'Contact number is required'}, maxLength: {value: 13, message: 'Maximum length is 13 characters'}, minLength: {value: 10, message: 'Minimum of 10 characters required'}})}/> 
                    {handleError("contactNumber")}
                </FormGroup> 
                {/* pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" */}
                <FormGroup>
                    <FormLabel>First name <span>*</span></FormLabel>
                    <FormControl data-cy="firstname" value={flowData?.signUpFormData?.firstName} type="text" name="firstName" className="form-input" placeholder="Jane" ref={register({required:{value: true, message:'First name is required'}})}/>
                    {handleError("firstName")}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Last name <span>*</span></FormLabel>
                    <FormControl data-cy="lastname" value={flowData?.signUpFormData?.lastName} type="text" name="lastName" className="form-input" placeholder="Brown" ref={register({required:{value: true, message:'Last name is required'}})}/>
                    {handleError("lastName")}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Password <span>*</span></FormLabel>
                    <FormControl data-cy="password" type="password" name="password" className="form-input" ref={register({required:{value: true, message:'Password is required'}, maxLength: {value: 50, message: 'Password max lenght is 50'}, minLength: {value: 8, message: 'Minimum of 8 characters required'}})}/>
                    {handleError("password")}
                </FormGroup>
                <FormGroup className="mb-5">
                    <FormLabel>Company name <span>*</span></FormLabel>
                    <FormControl data-cy="companyName"  type="text" name="companyName" className="form-input" ref={register({required:{value: true, message:'Company name is required'}, maxLength: {value: 50, message: 'Company name max lenght is 50'}, minLength: {value: 3, message: 'Minimum of 3 characters required'}})}/>
                    {handleError("companyName")}
                </FormGroup>
                <FormGroup>
                    <input data-cy="next-step" type="submit" className="btn btn-lg btn-signup" value="Next"></input>
                </FormGroup>
            </Form>
            

            <AlertModal title="Sign Up" type={AlertType.Processing} state={creatingAlertSate} />
            <AlertModal type={AlertType.Error} state={errorAlertSate} /> 
        </div>
    );
}

export default CompanySignUpForm;
