import React, { useContext, useState } from 'react';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { signInWithFacebook } from '../../../services/auth';
import { AuthenticationContext } from '../../../contexts/AuthenticationContext';
import { useAppDispatch } from '../../../redux/hooks';
import { loadUser } from '../../../redux/slices/auth-slice/auth-slice';
import glogo from "../../../assets/images/google-logo.png";
import flogo from "../../../assets/images/facebook-logo.png";
import alogo from "../../../assets/images/apple-logo.png";
import './OAuthLogin.css';

const OAuthLogin: React.FunctionComponent<{}> = () => {
    const [processingAlertState, setProcessingAlertState] = useState<AlertState>({show: false});
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({show: false});
    const {setCurrentUser} = useContext(AuthenticationContext);
    const dispatch = useAppDispatch();
    
    
    const handleGmailLogin = () => {
        alert('Google Sign In');
    }

    const handleFacebookLogin = async () => {
        try {
            setProcessingAlertState({show: true, message: ''});
            const creds = await signInWithFacebook();
            if(creds && setCurrentUser){
                await setCurrentUser('facebook-login', 'password');
                dispatch(loadUser());
                setProcessingAlertState({show: false});
            }
        } catch (error) {
            setErrorAlertState({show: true, message: (error as Error).message});
        }
    }

    return (
        <div id="oauth-login-container">
            <button type="button" className="btn btn-oauth" onClick={handleGmailLogin}>
                <img src={glogo} alt="Google" ></img>
                &nbsp;
                <span className="btn-text">Google</span>
            </button>
            <button type="button" className="btn btn-oauth" onClick={handleFacebookLogin}>
                <img src={alogo} alt="Apple"></img>
                &nbsp;
                <span className="btn-text">Apple</span>
            </button>
            <button type="button" className="btn btn-oauth" onClick={handleFacebookLogin}>
                <img src={flogo} alt="Facebook"></img>
                &nbsp;
                <span className="btn-text">Facebook</span>
            </button>

            <AlertModal title="Login" type={AlertType.Processing} state={processingAlertState} />
            <AlertModal type={AlertType.Error} state={errorAlertState} /> 
        </div>
    );
}

export default OAuthLogin
