import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AppStore from './redux/store';
import requestEventQueueManager from './services/sync/event/request/request-event-queue-manager';
import responseEventQueueManager from './services/sync/event/response/response-event-queue-manager';
import { OnlineStatusProvider } from './utilities/useOnlineStatus/useOnlineStatus';
import { PersistGate } from 'redux-persist/integration/react'

const {store, persistor} = AppStore();
ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<>Fetching data...</>} persistor={persistor}>
        <OnlineStatusProvider>
          <App />
        </OnlineStatusProvider>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// process queued request
requestEventQueueManager.processQueuedEvents();
//process queued responses
responseEventQueueManager.processQueuedResponses();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
