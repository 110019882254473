import React, { Component } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import BackButton from '../../BackButton/BackButton'
import './CardFormWrapper.css'

export class CardFormWrapper extends Component<{title: string, subTitle?: string | React.ReactNode, childComponent: React.ReactNode, prevStep?: () => void}> {
    render() {
        const {title, childComponent, subTitle, prevStep} = this.props;
        const back = (e?: { preventDefault: () => void; }) => {
            e?.preventDefault();
            if(prevStep) prevStep();
        };

        return (
            <div className="container">
                <Row>
                    <Col sm={9} md={7} lg={5} className="mx-auto my-5">
                        <Card className="signup-card-wrapper">
                            <CardBody>
                                <Row className="mb-4">
                                    <Col lg={1} md={1}>
                                        <BackButton onClick={back}/>
                                    </Col>
                                    <Col md={10}>
                                        <div>
                                            <p id="title">{title}</p>
                                            <p className="sub-title mb-3">{subTitle}</p>
                                        </div>
                                    </Col>
                                </Row>
                                {childComponent}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CardFormWrapper
