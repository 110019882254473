import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { FetchPaymentPlansQuery, GetPaymentResultsQuery, SubscribeToPaymentPlanResponse } from "../../../API";
import { APIMutations } from "../../../graphql/mutations";
import { APIQueries } from "../../../graphql/queries";
import { MixpanelEvent, track } from "../../../helpers/analytics/mixpanel-helper";
import { PaymentPlanSubscription } from "../../../interfaces/payment-slice/payment-plan-subscription";
import { PaymentPlan } from "../../../models/PaymentPlan";

export async function fetchAppPaymentPlans(){
    // *Working with this

    const result = await (API.graphql({
        query: APIQueries.fetchPaymentPlans
      }) as Promise<GraphQLResult<FetchPaymentPlansQuery>>);

      if (!result.data || !result.data.fetchPaymentPlans) {
        throw Error('Failed to get paymentplans ');
      }

      const records = result.data.fetchPaymentPlans.map<PaymentPlan>(p => {
        return {
            id: p.SK,
            planName: p.PlanName,
            cost: p.Cost,
            plan: p.Plan,
            benefits: p.Benefits,
            createdBy: p._createdBy,
            deleted: p._deleted,
            version: p._version
        }
      });

      return {
        data: records
      };
}

export async function subscribeToPaymentPlan (planSubscription: PaymentPlanSubscription) {
    const result = await (API.graphql({
        query: APIMutations.subscribeToPaymentPlan,
        variables: {
            payload: {
                account: planSubscription.accountId,
                subscription: planSubscription.planId
            }
        }
      }) as Promise<GraphQLResult<SubscribeToPaymentPlanResponse>>);

      if (!result.data || !result.data.subscribeToPaymentPlan.paymentRedirectUrl) {
        throw Error('Failed to subscribe to paymentplan');
      }

      const paymentRedirectUrl = result.data.subscribeToPaymentPlan.paymentRedirectUrl;
      const plan = result.data.subscribeToPaymentPlan.paymentPlan;

      track({event: MixpanelEvent.PLAN_SUBSCRIPTION, props:{
        'plan': plan.Plan,
        'plan name': plan.PlanName,
        'plan type': plan.Type,
        'benefits': plan.Benefits,
        'created by': plan._createdBy,
      }});
      
      return {
        paymentRedirectUrl,
        data: {
            id: plan.SK,
            planName: plan.PlanName,
            cost: plan.Cost,
            plan: plan.Plan,
            benefits: plan.Benefits,
            createdBy: plan._createdBy,
            deleted: plan._deleted,
            version: plan._version
        }
      };
}


export async function getPaymentResults(accountId: string, receiptId: string){
    // *Working with this

    const result = await (API.graphql({
        query: APIQueries.getPaymentResults,
        variables: {
            payload: {
                account: accountId,
                transactionId: receiptId
            }
        }
      }) as Promise<GraphQLResult<GetPaymentResultsQuery>>);

      if (!result.data || !result.data.getPaymentResults) {
        throw Error('Failed to get payment results ');
      }

      return result.data.getPaymentResults;
}