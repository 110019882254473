import { ErrorMessage } from '@hookform/error-message';
import { FormControl, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Form, FormGroup } from 'reactstrap';
import './IndividualSignUpForm.css';
import { SignUpFormInterface } from '../../../interfaces/signup-form-interface';
import { signUp, SignUpData } from '../../../services/auth';
import AlertModal, { AlertState, AlertType } from '../../../helpers/AlertModal';
import { FunctionComponent, useState } from 'react';


interface SignUpProp{
    initialFormData: SignUpFormInterface,
    nextStep: (email: string, name: string, password: string) =>void,
}

const IndividualSignUpForm: FunctionComponent<SignUpProp> = (props) =>{
    const {nextStep} = props;
    const [creatingAlertState, setCreatingAlertState] = useState<AlertState>({show:false, message:""});
    const [errorAlertState, setErrorAlertState] = useState<AlertState>({show: false, message:""});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [contact, setContact] = useState('');
    const [username, setUsername] = useState('');
    
    const {register, handleSubmit, errors} = useForm<SignUpFormInterface>({criteriaMode: "all"});
    const onSubmit = async (data: SignUpFormInterface) =>{
        ///**TEMP: fix cognito not sending conf code when phone nos present */
        data.contactNumber = '';
        //** */
        await handleSignUp(data);
    }

    const handleSignUp = async (data: SignUpFormInterface) => {
        try {
            const {username, password, firstName, lastName, contactNumber} = data;
            let signUpData: SignUpData = {
                username: username,
                password: password,
                attributes: {
                    email: username,
                    given_name: firstName,
                    family_name: lastName,
                    phone_number: contactNumber,
                    isCompany: 'false'
                }
            }

            setCreatingAlertState({show: true, message: "Creating account..."});
            const signUpResult = await signUp(signUpData);
            if(signUpResult) {
                setCreatingAlertState({show:false});
                nextStep(data.username, `${data.firstName} ${data.lastName}`, data.password);
            }

            setCreatingAlertState({show: false});
            setErrorAlertState({show: true, message: "Failed to create account. Please try again."});
        } catch (error) {
            setCreatingAlertState({show: false});
            setErrorAlertState({show: true, message: (error as Error).message ?? "Failed to create account. Please try again."});
        }
    }

    const handleError = (elementName: any) => {
        return <ErrorMessage
            errors={errors}
            name={elementName}
            render={({ messages }) => {
            console.log("messages", messages);
            return messages
                ? Object.entries(messages).map(([type, message]) => (
                    <p className="error" key={type}>{message}</p>
                ))
                : null;
            }}
        />
    }

    return(
        <div id="signup__form__container">
            <Form id="signup__form" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <FormLabel>Username <span>*</span></FormLabel>
                    <FormControl data-cy="username" value={username} onChange={(event) => setUsername(event.target.value)} type="text" name="username" className="form-input" placeholder="Email or Phone number" ref={register({required: {value: true, message: 'Username is required'}, maxLength: {value: 50, message:'Maximum 50 characters'}})}/>
                    <ErrorMessage
                        errors={errors}
                        name="username"
                        render={({ messages }) => {
                        console.log("messages", messages);
                        return messages
                            ? Object.entries(messages).map(([type, message]) => (
                                <p className="error" key={type}>{message}</p>
                            ))
                            : null;
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Contact number</FormLabel>
                    <FormControl data-cy="contact" value={contact} type="tel" onChange={(event) => setContact(event.target.value)} name="contactNumber"  placeholder="+18760011223" className="form-input" ref={register({required:{value: false, message:'Contact number is required'}})}/> 
                    {handleError("contactNumber")}
                </FormGroup> 
                {/* pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" */}
                <FormGroup>
                    <FormLabel>First name <span>*</span></FormLabel>
                    <FormControl data-cy="firstname" value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" name="firstName" className="form-input" placeholder="Jane" ref={register({required:{value: true, message:'First name is required'}})}/>
                    {handleError("firstName")}
                </FormGroup>
                <FormGroup>
                    <FormLabel>Last name <span>*</span></FormLabel>
                    <FormControl data-cy="lastname" value={lastName} onChange={(event) => setLastName(event.target.value)} type="text" name="lastName" className="form-input" placeholder="Brown" ref={register({required:{value: true, message:'Last name is required'}})}/>
                    {handleError("lastName")}
                </FormGroup>
                <FormGroup className="mb-5">
                    <FormLabel>Password <span>*</span></FormLabel>
                    <FormControl data-cy="password" type="password" name="password" className="form-input" ref={register({required:{value: true, message:'Password is required'}, maxLength: {value: 50, message: 'Password max lenght is 50'}, minLength: {value: 8, message: 'Minimum of 8 characters required'}})}/>
                    {handleError("password")}
                </FormGroup>
                <FormGroup>
                    <input data-cy="next-step" type="submit" className="btn btn-lg btn-signup" value="Next"></input>
                </FormGroup>
            </Form>
            
            <AlertModal title="Sign Up" type={AlertType.Processing} state={creatingAlertState} />
            <AlertModal type={AlertType.Error} state={errorAlertState} /> 
        </div>
    );
}

export default IndividualSignUpForm;
