import { configureStore, ThunkAction, Action, AnyAction, combineReducers, CombinedState  } from '@reduxjs/toolkit';
import authSlice, { AuthState } from './slices/auth-slice/auth-slice';
import listSlice, { ListState } from './slices/list-slice/list-slice';
import syncSlice, { SyncState } from './slices/sync-slice/sync-slice';
import workflowSlice, {WorkflowState} from './slices/workflow-slice/workflow-slice';
import paymentSlice, {PaymentState} from './slices/payment-slice/payment-slice';
import { persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}

const combinedReducer = combineReducers(
  {
    auth: authSlice,
    workflow: workflowSlice,
    payment: paymentSlice,
    list: listSlice,
    sync: syncSlice,
  }
);

// function to delete cookies
function clearSiteData() {
  var Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (var i = 0; i < Cookies.length; i++)
  document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
  //*clear local store
  localStorage.clear();
}


const rootReducer = (state: CombinedState<{ auth: AuthState, workflow: WorkflowState, payment: PaymentState, list: ListState, sync: SyncState}> | undefined, action: AnyAction) => {
  if (action.type === "user/signout/fulfilled") { // check for action type 
    storage.removeItem('persist:root');
    clearSiteData();//*
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,//rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
});
const persistor = persistStore(store);
const AppStore =  () => {
  return { store, persistor }
};
export default AppStore;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
