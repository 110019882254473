import { Tap } from 'tap-types/';
import { AccountService, TapTables } from "tap-types/lib/AccountService/";
import { CompanionAppUserProperties, ContactProperties, TapInviteProperties, TapRoleProperties, TapSiteProperties, TapUserProperties, TapVisitorProperties, UserFields } from '../constants';
import { EventType } from "../models/event-type";
import TableColumn from '../models/table-column';
import TapRow from '../models/tap-row';
import { Workflow } from '../models/workflow';
import { generate } from 'generate-password';

const updateTableMapping: Map<TapTables, EventType> = new Map([
    [TapTables.app_users, EventType.UpdateUser],
    [TapTables.users, EventType.UpdateUser],
    [TapTables.invite, EventType.UpdateInvite],
    [TapTables.visitors, EventType.UpdateVisitor],
    [TapTables.roles, EventType.UpdateRole],
    [TapTables.sites, EventType.UpdateSiteList],
    [TapTables.companion_app, EventType.updateCompanionAppUser]

]);

const createTableMapping: Map<TapTables, EventType> = new Map([
    [TapTables.app_users, EventType.CreateUser],
    [TapTables.users, EventType.CreateUser],
    [TapTables.invite, EventType.CreateInvite],
    [TapTables.visitors, EventType.CreateVisitor],
    [TapTables.roles, EventType.CreateRole],
    [TapTables.companion_app, EventType.createCompanionAppUser]
]);

export class WorkflowHelpers {
    static getUpdateType = (workflowId: string) => {

        const eventType = updateTableMapping.get(workflowId as TapTables);

        return eventType ?? EventType.UpdateWorkflowRecord;
    }


    static getCreateType = (workflowId: string) => {
        const eventType = createTableMapping.get(workflowId as TapTables);

        return eventType ?? EventType.CreateWorkflowRecord;
    }

    static setUpdatedRowProperty = (row: TapRow, tableColumn: TableColumn, value: any) => {

        const updatedRow: TapRow = {
            ...row,
            rowProperties: [
                ...row.rowProperties.filter(property => property.key !== tableColumn.id),
                {
                    key: tableColumn.id,
                    value: value
                }
            ]
        };

        return updatedRow;
    }

    static getRecordUpdate = (record: TapRow, workflowId: string, user: Tap.UserService.User, workflows: Workflow[]) => {

        const userAccount = user.accounts.find(account => account.accountId === record.accountId);

        if (!userAccount) {
            throw Error('Cannot find related user account');
        }

        switch (workflowId) {
            case TapTables.visitors:
                return createVisitorUpdate(record);
            case TapTables.app_users:
                return createdUserUpdate(record, userAccount);
            case TapTables.invite:
                return createInviteUpdate(record, workflows);
            case TapTables.companion_app:
                return createdCompanionAppUserUpdate(record, userAccount);
                case TapTables.roles:
                    return createdRoleRecordUpdate(record, userAccount);
        }
    }

    static getCreateRecordInput = (record: TapRow, workflowId: string, user: Tap.UserService.User, workflows: Workflow[]) => {

        const userAccount = user.accounts.find(account => account.accountId === record.accountId);

        if (!userAccount) {
            throw Error('Cannot find related user account');
        }

        switch (workflowId) {
            case TapTables.visitors:
                return createVisitor(record, userAccount);
            case TapTables.app_users:
                return createUser(record, userAccount);
            case TapTables.invite:
                return createIvite(record, workflows, userAccount);
            case TapTables.companion_app:
                return createCompanionAppUser(record, userAccount);
                case TapTables.roles:
                    return createRoleRecord(record, userAccount);
        }
    }

    static overwriteRowProperty = (row: TapRow, key: string, value: any) => {

        if (!value) {
            return;
        }

        const propertyIndex = row.rowProperties.findIndex(property => property.key === key);

        if (propertyIndex !== -1) {
            row.rowProperties.splice(propertyIndex, 1);
        }

        row.rowProperties.push({
            key: key,
            value: value
        });
    }
}


function createVisitorUpdate(record: TapRow) {
    let visitor = new Tap.AccountService.WorkflowRecord.VisitorUpdate({
        accountId: record.accountId,
        createdBy: record.createdBy,
        deleted: record.deleted,
        recordId: record.id,
        version: record.version ?? 1,
        attributes: {
            accountId: record.accountId,
            id: record.id,
            userEmail: record.createdBy,
            date: record.rowProperties.find(property => property.key === TapVisitorProperties.date)?.value,
            rowProperties: record.rowProperties
        }
    });
    delete visitor.gsi1pk;
    delete visitor.gsi1sk;
    return visitor;
}

function createVisitor(record: TapRow, userAccount: Tap.UserService.UserAccount) {
    const date = new Date().toISOString();

    WorkflowHelpers.overwriteRowProperty(record, TapVisitorProperties.date, date);
    WorkflowHelpers.overwriteRowProperty(record, TapVisitorProperties.signIn, date);

    let visitor = new AccountService.WorkflowRecord.VisitorInput(record.accountId, record.id, userAccount.email, {
        accountId: record.accountId,
        id: record.id,
        date: date,
        userEmail: userAccount.email,
        rowProperties: record.rowProperties
    });
    delete visitor.gsi1pk;
    delete visitor.gsi1sk;
    return visitor;
}

function createUser(record: TapRow, userAccount: Tap.UserService.UserAccount) {
    WorkflowHelpers.overwriteRowProperty(record, TapUserProperties.invitationAccepted, false);
    WorkflowHelpers.overwriteRowProperty(record, TapUserProperties.linkExpiration, 0);

    const userRecord = new AccountService.User.AccountUserInput(record.accountId, userAccount.email, {
        email: record.rowProperties.find(property => property.key === TapUserProperties.email)?.value,
        firstName: record.rowProperties.find(property => property.key === TapUserProperties.firstName)?.value,
        lastName: record.rowProperties.find(property => property.key === TapUserProperties.lastName)?.value,
        locationIds: record.rowProperties.find(property => property.key === TapUserProperties.locationIds)?.value,
        roleIds: record.rowProperties.find(property => property.key === TapUserProperties.roleIds)?.value,
        invitationDetails: {
            companyName: userAccount.companyName,
            invitationAccepted: false,
            linkExpiration: 0,
            inviteType: Tap.AccountService.InviteType.TAP_USER_INVITE
        }
    });
    return userRecord;
}

function createdUserUpdate(record: TapRow, userAccount: Tap.UserService.UserAccount) {
    return new Tap.AccountService.User.AccountUserUpdate({
        accountId: record.accountId,
        createdBy: record.createdBy,
        deleted: record.deleted,
        version: record.version ?? 1,
        attributes: {
            profilePicture: record.rowProperties.find(property => property.key === UserFields.profilePicture)?.value,
            email: record.rowProperties.find(property => property.key === TapUserProperties.email)?.value,
            firstName: record.rowProperties.find(property => property.key === TapUserProperties.firstName)?.value,
            lastName: record.rowProperties.find(property => property.key === TapUserProperties.lastName)?.value,
            locationIds: record.rowProperties.find(property => property.key === TapUserProperties.locationIds)?.value,
            roleIds: record.rowProperties.find(property => property.key === TapUserProperties.roleIds)?.value,
            invitationDetails: {
                companyName: userAccount.companyName,
                invitationAccepted: record.rowProperties.find(property => property.key === TapUserProperties.invitationAccepted)?.value,
                linkExpiration: record.rowProperties.find(property => property.key === TapUserProperties.linkExpiration)?.value,
                acceptedBy: record.rowProperties.find(property => property.key === TapUserProperties.acceptedBy)?.value,
                inviteType: record.rowProperties.find(property => property.key === TapUserProperties.inviteType)?.value,
            }
        }
    });
}

// function createCompanionAppUser(record: TapRow, userAccount: Tap.UserService.UserAccount) {

//     const userRecord = new AccountService.User.CompanionAppUserInput(record.accountId, userAccount.email, {
//         name: record.rowProperties.find(property => property.key === CompanionAppUserProperties.name)?.value,
//         phone: record.rowProperties.find(property => property.key === CompanionAppUserProperties.phone)?.value,
//         username: record.rowProperties.find(property => property.key === CompanionAppUserProperties.username)?.value,
//         address: record.rowProperties.find(property => property.key === CompanionAppUserProperties.address)?.value,
//         houseMates: record.rowProperties.find(property => property.key === CompanionAppUserProperties.houseMates)?.value,
//         unit: record.rowProperties.find(property => property.key === CompanionAppUserProperties.unit)?.value,
//     });
//     return userRecord;
// }

function createRoleRecord(record: TapRow, userAccount: Tap.UserService.UserAccount){
    const roleRecord = new Tap.AccountService.Role.AccountRoleInput(record.accountId,record.id, userAccount.email,{
        id: record.id,
        name: record.rowProperties.find(prop => prop.key === TapRoleProperties.name)?.value,
        description: record.rowProperties.find(prop => prop.key === TapRoleProperties.description)?.value,
        permissions: record.rowProperties.find(prop => prop.key === TapRoleProperties.permissions)?.value,
    });
    return roleRecord;
}

function createdRoleRecordUpdate(record: TapRow, userAccount: Tap.UserService.UserAccount){
    const roleRecord = new Tap.AccountService.Role.AccountRoleUpdate({
        accountId: record.accountId,
        roleId: record.id,
        createdBy: userAccount.email,
        deleted: false,
        version: record.version ?? 1,
        attributes: {
            id: record.id,
            name: record.rowProperties.find(prop => prop.key === TapRoleProperties.name)?.value,
            description: record.rowProperties.find(prop => prop.key === TapRoleProperties.description)?.value,
            permissions: record.rowProperties.find(prop => prop.key === TapRoleProperties.permissions)?.value,
        }
    });
    return roleRecord;
}

function createCompanionAppUser(record: TapRow, userAccount: Tap.UserService.UserAccount) {
    const permissions = [AccountService.Permission.CreateCompanionAppUserRecord, AccountService.Permission.EditCompanionAppUserRecord, AccountService.Permission.DeleteCompanionAppUserRecord, AccountService.Permission.CreateInviteRecord,];
    const email =  record.rowProperties.find(property => property.key === CompanionAppUserProperties.email)?.value;
    const userRecord = new AccountService.User.CompanionAppUserInput(record.accountId, userAccount.email, {
        name: record.rowProperties.find(property => property.key === CompanionAppUserProperties.name)?.value,
        phone: record.rowProperties.find(property => property.key === CompanionAppUserProperties.phone)?.value,
        email: email,
        profilePicture: record.rowProperties.find(property => property.key === CompanionAppUserProperties.profilePicture)?.value,
        username: record.rowProperties.find(property => property.key === CompanionAppUserProperties.username)?.value ?? email,
        address: record.rowProperties.find(property => property.key === CompanionAppUserProperties.address)?.value,
        houseMates: record.rowProperties.find(property => property.key === CompanionAppUserProperties.houseMates)?.value,
        unit: record.rowProperties.find(property => property.key === CompanionAppUserProperties.unit)?.value,
        locationIds: record.rowProperties.find(property => property.key === CompanionAppUserProperties.locationIds)?.value,
        roleIds: record.rowProperties.find(property => property.key === CompanionAppUserProperties.roleIds)?.value,
        permissions: record.rowProperties.find(property => property.key === CompanionAppUserProperties.permissions)?.value ?? permissions,
        invitationDetails: {
            companyName: userAccount.companyName,
            invitationAccepted: false,
            linkExpiration: 0,
            inviteType: Tap.AccountService.InviteType.COMPANION_APP_USER_INVITE,
        }
    });
    return userRecord;
}

// function createdCompanionAppUserUpdate(record: TapRow, userAccount: Tap.UserService.UserAccount) {
//     return new Tap.AccountService.User.CompanionAppUserUpdate({
//         accountId: record.accountId,
//         createdBy: record.createdBy,
//         deleted: record.deleted,
//         version: record.version ?? 1,
//         attributes: {
//             name: record.rowProperties.find(property => property.key === CompanionAppUserProperties.name)?.value,
//             phone: record.rowProperties.find(property => property.key === CompanionAppUserProperties.phone)?.value,
//             username: record.rowProperties.find(property => property.key === CompanionAppUserProperties.username)?.value,
//             address: record.rowProperties.find(property => property.key === CompanionAppUserProperties.address)?.value,
//             houseMates: record.rowProperties.find(property => property.key === CompanionAppUserProperties.houseMates)?.value,
//             unit: record.rowProperties.find(property => property.key === CompanionAppUserProperties.unit)?.value
//         }
//     });
// }

function createdCompanionAppUserUpdate(record: TapRow, userAccount: Tap.UserService.UserAccount) {
    return new Tap.AccountService.User.CompanionAppUserUpdate({
        accountId: record.accountId,
        createdBy: record.createdBy,
        deleted: record.deleted,
        version: record.version ?? 1,
        attributes: {
            email:  record.rowProperties.find(property => property.key === CompanionAppUserProperties.email)?.value,
            name: record.rowProperties.find(property => property.key === CompanionAppUserProperties.name)?.value,
            phone: record.rowProperties.find(property => property.key === CompanionAppUserProperties.phone)?.value,
            profilePicture: record.rowProperties.find(property => property.key === CompanionAppUserProperties.profilePicture)?.value,
            username: record.rowProperties.find(property => property.key === CompanionAppUserProperties.username)?.value,
            address: record.rowProperties.find(property => property.key === CompanionAppUserProperties.address)?.value,
            houseMates: record.rowProperties.find(property => property.key === CompanionAppUserProperties.houseMates)?.value,
            unit: record.rowProperties.find(property => property.key === CompanionAppUserProperties.unit)?.value,
            locationIds: record.rowProperties.find(property => property.key === CompanionAppUserProperties.locationIds)?.value,
            roleIds: record.rowProperties.find(property => property.key === CompanionAppUserProperties.roleIds)?.value,
            permissions: record.rowProperties.find(property => property.key === CompanionAppUserProperties.permissions)?.value,
            invitationDetails: {
                companyName: userAccount.companyName,
                invitationAccepted: record.rowProperties.find(property => property.key === CompanionAppUserProperties.invitationAccepted)?.value,
                linkExpiration: record.rowProperties.find(property => property.key === CompanionAppUserProperties.linkExpiration)?.value,
                acceptedBy: record.rowProperties.find(property => property.key === CompanionAppUserProperties.acceptedBy)?.value,
                inviteType: record.rowProperties.find(property => property.key === CompanionAppUserProperties.inviteType)?.value,
            }
        }
    });
}

function createIvite(record: TapRow, workflows: Workflow[], userAccount: Tap.UserService.UserAccount) {
    const otp = generate({ numbers: true, length: 8 });
    const inviteDetails = getInviteDetails(record, workflows);


    const date = new Date().toISOString();
    const timeZone = new Date().toString().match(/([A-Z]){3}/)![0];
    //const offsetInHours = new Date().getTimezoneOffset()/60;

    WorkflowHelpers.overwriteRowProperty(record, TapInviteProperties.inviteSent, date);
    WorkflowHelpers.overwriteRowProperty(record, TapInviteProperties.dateCreated, date);
    WorkflowHelpers.overwriteRowProperty(record, TapInviteProperties.otp, otp);
    WorkflowHelpers.overwriteRowProperty(record, TapInviteProperties.timeZone, timeZone);

    const invite = new AccountService.WorkflowRecord.InviteInput(record.accountId, record.id, userAccount.email, {
        accountId: record.accountId,
        date: record.rowProperties.find(property => property.key === TapInviteProperties.dateCreated)?.value,
        hostName: inviteDetails.hostName,
        locationName: inviteDetails.locationName,
        id: record.id,
        otp: otp,
        userEmail: inviteDetails.hostEmail,
        email: inviteDetails.invitesEmail,
        phone: inviteDetails.invitesPhone,
        emailNotifyStatus: Tap.AccountService.InviteeNotifyStatus.UNKNOWN,
        smsNotifyStatus: Tap.AccountService.InviteeNotifyStatus.UNKNOWN,
        rowProperties: [
            ...record.rowProperties
        ]
    });
    delete invite.gsi1pk;
    delete invite.gsi1sk;
    return invite;
}


function createInviteUpdate(record: TapRow, workflows: Workflow[]) {
    const inviteDetails = getInviteDetails(record, workflows);
     
    let dateCreated = record.rowProperties.find(property => property.key === TapInviteProperties.dateCreated)?.value;
    dateCreated ??= (record.rowProperties.find(property => (property as any)?.[TapInviteProperties.dateCreated]) as any)?.[TapInviteProperties.dateCreated]; //* to correct malformed mobile app invite payload
    const invite = new Tap.AccountService.WorkflowRecord.InviteUpdate({
        accountId: record.accountId,
        createdBy: record.createdBy,
        deleted: record.deleted,
        recordId: record.id,
        version: record.version ?? 1,
        attributes: {
            accountId: record.accountId,
            hostName: inviteDetails.hostName,
            date: dateCreated,//*record.rowProperties.find(property => property.key === TapInviteProperties.dateCreated)?.value,
            locationName: inviteDetails.locationName,
            id: record.id,
            otp: record.rowProperties.find(property => property.key === TapInviteProperties.otp)?.value,
            userEmail: inviteDetails.hostEmail,
            email: inviteDetails.invitesEmail,
            phone: inviteDetails.invitesPhone,
            
            rowProperties: [
                ...record.rowProperties
            ]
        }
    });
    //*** */
    delete invite.gsi1pk;
    delete invite.gsi1sk;
    //*** */
    return invite;
}

const getInviteDetails = (record: TapRow, workflows: Workflow[]) => {
    const locationId = record.rowProperties.find(property => property.key === TapInviteProperties.location)!.value[0];
    const locationName = workflows.find(wf => wf.accountId === record.accountId && wf.id === TapTables.sites)?.rows.find(row => row.id === locationId)?.rowProperties.find(property => property.key === TapSiteProperties.name)?.value;
    const hostId = record.rowProperties.find(property => property.key === TapInviteProperties.host)!.value[0];
    const host = workflows.find(wf => wf.id === TapTables.app_users && wf.accountId === record.accountId)?.rows.find(row => row.id === hostId);
    const hostName = `${host?.rowProperties.find(property => property.key === TapUserProperties.firstName)?.value} ${host?.rowProperties.find(property => property.key === TapUserProperties.lastName)?.value}`;

    const contact = record.rowProperties.find(property => property.key === TapInviteProperties.contact)?.value;
    let invitesEmail: undefined | string = undefined;
    let invitesPhone: undefined | string = undefined;


    if (contact !== undefined && Array.isArray(contact)) {
        invitesEmail = contact.find(property => property.key === ContactProperties.email)?.value;
        invitesPhone = contact.find(property => property.key === ContactProperties.phone)?.value;

    }

    return {
        locationId,
        locationName,
        hostName,
        hostEmail: hostId,
        invitesEmail,
        invitesPhone
    };
}