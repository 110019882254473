import { v4 as uuidv4 } from 'uuid';
import { APIMutations } from '../graphql/mutations';
import { EventType } from './event-type';
export class RequestEvent {
    readonly eventId: string;
    graphQLMutation: string;
    variables: object;
    dateTime: Date;
    eventType: EventType;
    requestState: "in-queue" | "processing" | "failed" | "success" | "new"

    constructor(variables: object, eventType: EventType) {
        this.dateTime = new Date();
        this.eventId = uuidv4();
        this.variables = variables;
        this.requestState = "new";
        this.eventType = eventType;

        switch (eventType) {
            case EventType.CreateList:
                this.graphQLMutation = '';
                break;
            case EventType.CreateVisitor:
                this.graphQLMutation = APIMutations.createVisitor;
                break;
            case EventType.CreateUser:
                this.graphQLMutation = APIMutations.createUser;
                break;
            case EventType.CreateInvite:
                this.graphQLMutation = APIMutations.createInvite;
                break;
            case EventType.UpdateInvite:
                this.graphQLMutation = APIMutations.updateInvite;
                break;
            case EventType.UpdateUser:
                this.graphQLMutation = APIMutations.updateUser;
                break;
            case EventType.UpdateVisitor:
                this.graphQLMutation = APIMutations.updateVisitor;
                break;
            case EventType.createOrUpdateLocation:
                this.graphQLMutation = APIMutations.addOrUpdateLocation;
                break;
            case EventType.CreateRole:
                this.graphQLMutation = APIMutations.createRole;
                break;
            case EventType.UpdateRole:
                this.graphQLMutation = APIMutations.updateRole;
                break;
            case EventType.createCompanionAppUser: ///Using same endpoint to take advantage of invite link
                this.graphQLMutation = APIMutations.createUser;
                break;
            default:
                this.graphQLMutation = '';
        }
    }
}